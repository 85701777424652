// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Input from "../Input.mjs";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Domains from "../../../model/src/domains/Domains.mjs";
import * as Mantine from "../Mantine.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as UsersStore from "../UsersStore.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "../ContextMenu.mjs";
import * as DomainStore from "../DomainStore.mjs";
import * as ProfilePhoto from "../ProfilePhoto.mjs";
import * as Core from "@mantine/core";
import * as LoadingCircle from "../LoadingCircle.mjs";
import * as SimpleTooltip from "../SimpleTooltip.mjs";
import * as ViewerContext from "../ViewerContext.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as DomainWebUtils from "../DomainWebUtils.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as DomainDetails__Utils from "./DomainDetails__Utils.mjs";
import * as AnalyticsUtilsBundles from "../analyticsUtilsBundles.mjs";
import * as EditCollaboratorsPopup from "../EditCollaboratorsPopup.mjs";

var memberContainer = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: "minContent",
              tl: {
                hd: {
                  NAME: "fr",
                  VAL: 1.0
                },
                tl: {
                  hd: "minContent",
                  tl: /* [] */0
                }
              }
            }),
        tl: {
          hd: Css.gridGap(Css.px(8)),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.paddingBottom(Css.px(16)),
              tl: {
                hd: Css.lastChild({
                      hd: Css.paddingBottom("zero"),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var workspaceMemberContainer = Curry._1(Css.style, {
      hd: Css.margin(Css.px(-16)),
      tl: {
        hd: Css.marginTop(Css.px(0)),
        tl: {
          hd: Css.marginBottom(Css.px(0)),
          tl: {
            hd: Css.padding(Css.px(16)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light02),
              tl: {
                hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light06),
                tl: {
                  hd: Css.borderLeftWidth("zero"),
                  tl: {
                    hd: Css.borderRightWidth("zero"),
                    tl: {
                      hd: Css.lastChild({
                            hd: Css.paddingBottom(Css.px(16)),
                            tl: {
                              hd: Css.marginBottom(Css.px(-16)),
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var container = Curry._1(Css.style, {
      hd: Css.margin2(Css.px(0), Css.px(-16)),
      tl: {
        hd: Css.padding(Css.px(16)),
        tl: {
          hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
          tl: /* [] */0
        }
      }
    });

var searchSection = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.gridGap(Css.px(8)),
          tl: {
            hd: Css.gridTemplateColumns({
                  hd: {
                    NAME: "fr",
                    VAL: 1.0
                  },
                  tl: {
                    hd: "maxContent",
                    tl: {
                      hd: "maxContent",
                      tl: /* [] */0
                    }
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

function dropdown(offset) {
  var variant = offset.NAME;
  var tmp;
  if (variant === "y") {
    tmp = {
      hd: Css.translateY(Css.px(offset.VAL)),
      tl: /* [] */0
    };
  } else if (variant === "xy") {
    var match = offset.VAL;
    tmp = {
      hd: Css.translateX(Css.px(match[0])),
      tl: {
        hd: Css.translateY(Css.px(match[1])),
        tl: /* [] */0
      }
    };
  } else {
    tmp = {
      hd: Css.translateX(Css.px(offset.VAL)),
      tl: /* [] */0
    };
  }
  return {
          hd: Css.borderRadius(Css.px(5)),
          tl: {
            hd: Css.padding2(Css.px(4), Css.px(0)),
            tl: {
              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                            NAME: "num",
                            VAL: 0.10
                          }))),
              tl: {
                hd: Css.transforms(tmp),
                tl: /* [] */0
              }
            }
          }
        };
}

function ManageMembersModal$Member(Props) {
  var canManage = Props.canManage;
  var domain = Props.domain;
  var member = Props.member;
  var domains = Props.domains;
  var members = Props.members;
  var viewerIsDomainMember = Props.viewerIsDomainMember;
  var schemaId = WorkspaceContext.use().id;
  var sendActions = SendActionsContext.use();
  var viewerId = ViewerContext.use().id;
  var addToast = Toast.useAddToast();
  var schemaBundle = SchemaBundleContext.use();
  var match = React.useState(function (param) {
        return false;
      });
  var setLoading = match[1];
  var handleRemoveDomainMember = function (param) {
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    DomainWebUtils.Firebase.removeDomainMember({
          id: member.id,
          role: member.role,
          domainId: domain.id
        }, (function (_exn) {
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
            Curry._1(addToast, {
                  message: "Error removing member from domain",
                  toastType: "Error"
                });
          }), (function (param) {
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
            var nameOrEmail = DomainDetails__Utils.getNameOrEmail(member);
            Curry._1(addToast, {
                  message: nameOrEmail !== undefined ? nameOrEmail + " was removed from domain" : "Member removed from domain",
                  toastType: "Success"
                });
            AnalyticsRe.domainMemberRemoved(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, domain.id, domain.name, Curry._1(Domains.size, domains), members.length - 1 | 0, viewerIsDomainMember ? "DomainTeamMember" : "Admin", schemaId);
          }), schemaId, sendActions, viewerId, domain.id);
  };
  var nameOrEmail = DomainDetails__Utils.getNameOrEmail(member);
  var email = member.email;
  return React.createElement("div", {
              key: member.id,
              className: memberContainer
            }, React.createElement(ProfilePhoto.FromUserId.make, {
                  id: member.id,
                  size: 40
                }), React.createElement("div", undefined, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      children: nameOrEmail !== undefined ? nameOrEmail : "Missing information"
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light10,
                      children: email !== undefined ? email : React.createElement("i", undefined, "No email")
                    })), match[0] ? React.createElement(LoadingCircle.make, {
                    size: 24,
                    color: Styles.Color.light10
                  }) : React.createElement(ContextMenu.V2.make, {
                    position: "bottom-end",
                    children: React.createElement(ContextMenu.V2.Item.make, {
                          label: viewerId === member.id ? "Leave domain" : "Remove member",
                          onClick: handleRemoveDomainMember,
                          disabled: !canManage
                        })
                  }));
}

function ManageMembersModal$Content(Props) {
  var domain = Props.domain;
  var opened = Props.opened;
  var viewerIsDomainMember = Props.viewerIsDomainMember;
  var match = WorkspaceContext.use();
  var workspaceMembers = match.members;
  var schemaId = match.id;
  var sendActions = SendActionsContext.use();
  var viewerId = ViewerContext.use().id;
  var addToast = Toast.useAddToast();
  var domains = DomainStore.useDomains();
  var schemaBundle = SchemaBundleContext.use();
  var viewerRole = WorkspaceContext.useMemberRole();
  var canManage = (viewerIsDomainMember || viewerRole === "Admin") && !domain.archived;
  var memberIds = React.useMemo((function (param) {
          return Belt_List.toArray(Belt_List.mapU(workspaceMembers, (function (member) {
                            return member.id;
                          })));
        }), [workspaceMembers]);
  var workspaceUsers = UsersStore.use(memberIds);
  var domainMemberViewModels = DomainStore.useDomainMemberViewModels(domain.id, undefined);
  var domainMemberIdsSet = React.useMemo((function (param) {
          return Belt_SetString.fromArray(Belt_Array.mapU(domainMemberViewModels, (function (param) {
                            return param.id;
                          })));
        }), [domainMemberViewModels]);
  var match$1 = React.useState(function (param) {
        return "";
      });
  var setSearchFilter = match$1[1];
  var searchFilter = match$1[0];
  React.useEffect((function (param) {
          if (!opened) {
            Curry._1(setSearchFilter, (function (param) {
                    return "";
                  }));
          }
          
        }), [opened]);
  var filteredMemberViewModels = React.useMemo((function (param) {
          if (searchFilter.trim() === "") {
            return domainMemberViewModels;
          } else {
            return Belt_Array.keepU(domainMemberViewModels, (function (member) {
                          var stringToSearch = Belt_Option.getWithDefault(member.name, "") + Belt_Option.getWithDefault(member.email, "");
                          return Fuzzaldrin.score(stringToSearch, searchFilter) > 0.0015;
                        }));
          }
        }), [
        searchFilter,
        domainMemberViewModels
      ]);
  var filteredWorkspaceMemberViewModels = React.useMemo((function (param) {
          if (searchFilter.trim() === "") {
            return [];
          } else if (typeof workspaceUsers === "object") {
            if (workspaceUsers.NAME === "Errored") {
              return [];
            } else {
              return Belt_Array.keepMapU(workspaceUsers.VAL, (function (user) {
                            var stringToSearch = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.name), "") + Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.email), "");
                            if (!Belt_SetString.has(domainMemberIdsSet, user.id) && Fuzzaldrin.score(stringToSearch, searchFilter) > 0.0015) {
                              return user;
                            }
                            
                          }));
            }
          } else {
            return [];
          }
        }), [
        workspaceUsers,
        domainMemberIdsSet,
        searchFilter
      ]);
  var handleToggleDomainMember = function (user, toggled) {
    var fn = toggled ? DomainWebUtils.Firebase.addDomainMember : DomainWebUtils.Firebase.removeDomainMember;
    Curry._7(fn, {
          id: user.id,
          role: "member",
          domainId: domain.id
        }, (function (param) {
            Curry._1(addToast, {
                  message: toggled ? "Error adding member to domain team" : "Error removing member from domain team",
                  toastType: "Error"
                });
          }), (function (param) {
            var nameOrEmail = DomainDetails__Utils.getValueInOrderOfImportance(Caml_option.nullable_to_opt(user.name), Caml_option.nullable_to_opt(user.email));
            Curry._1(addToast, {
                  message: nameOrEmail !== undefined ? (
                      toggled ? React.createElement(React.Fragment, undefined, nameOrEmail + " was added to ", React.createElement("i", undefined, domain.name)) : React.createElement(React.Fragment, undefined, nameOrEmail + " was removed from ", React.createElement("i", undefined, domain.name))
                    ) : (
                      toggled ? React.createElement(React.Fragment, undefined, "Member added to ", React.createElement("i", undefined, domain.name)) : React.createElement(React.Fragment, undefined, "Member removed from ", React.createElement("i", undefined, domain.name))
                    ),
                  toastType: "Success"
                });
            var domainBundle = AnalyticsUtilsBundles.domain(domain.id, domain.name, Curry._1(Domains.size, domains));
            if (toggled) {
              return AnalyticsRe.domainMemberAdded(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, domainBundle.domainId, domainBundle.domainName, domainBundle.workspaceDomainCount, viewerIsDomainMember ? "DomainTeamMember" : "Admin", domainMemberViewModels.length + 1 | 0, schemaId);
            } else {
              return AnalyticsRe.domainMemberRemoved(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, domainBundle.domainId, domainBundle.domainName, domainBundle.workspaceDomainCount, domainMemberViewModels.length - 1 | 0, viewerIsDomainMember ? "DomainTeamMember" : "Admin", schemaId);
            }
          }), schemaId, sendActions, viewerId, domain.id);
  };
  return React.createElement("div", {
              className: container
            }, React.createElement("div", {
                  className: searchSection
                }, React.createElement(Input.make, {
                      value: searchFilter,
                      onChange: (function (v) {
                          Curry._1(setSearchFilter, (function (param) {
                                  return v;
                                }));
                        }),
                      placeholder: "Find team member...",
                      className: {
                        hd: Css.maxWidth(Css.px(240)),
                        tl: {
                          hd: Css.fontSize(Styles.FontSize.small),
                          tl: /* [] */0
                        }
                      },
                      type_: "search"
                    }), canManage ? React.createElement(Mantine.Popover.make, {
                        children: null
                      }, React.createElement(Core.Popover.Target, {
                            children: React.createElement(Button.make, {
                                  icon: "plus",
                                  label: "Add member"
                                })
                          }), React.createElement(Mantine.Popover.Dropdown.make, {
                            additionalStyles: dropdown({
                                  NAME: "xy",
                                  VAL: [
                                    0,
                                    0
                                  ]
                                }),
                            children: React.createElement(EditCollaboratorsPopup.WithUsers.make, {
                                  collaboratorIds: Belt_List.fromArray(Belt_Array.mapU(domainMemberViewModels, (function (param) {
                                              return param.id;
                                            }))),
                                  onToggle: handleToggleDomainMember,
                                  searchPlaceholder: "Find workspace members...",
                                  infoText: "",
                                  users: workspaceUsers
                                })
                          })) : React.createElement(SimpleTooltip.make, {
                        maxWidth: 250,
                        tooltip: "Only workspace admins and domain members can add new members",
                        children: React.createElement(Button.make, {
                              disabled: true,
                              icon: "plus",
                              label: "Add member"
                            })
                      })), React.createElement(Spacer.make, {
                  height: 16
                }), domainMemberViewModels.length !== 0 ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light10,
                        children: "Team members (" + String(domainMemberViewModels.length) + ")"
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), filteredMemberViewModels.length !== 0 ? Belt_Array.mapU(filteredMemberViewModels, (function (member) {
                            return React.createElement(ManageMembersModal$Member, {
                                        canManage: canManage,
                                        domain: domain,
                                        member: member,
                                        domains: domains,
                                        members: domainMemberViewModels,
                                        viewerIsDomainMember: viewerIsDomainMember,
                                        key: member.id
                                      });
                          })) : React.createElement($$Text.make, {
                          size: "Small",
                          textAlign: "center",
                          color: Styles.Color.light10,
                          children: "Looks like the person you are searching for is not a member of this domain team."
                        })) : React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    textAlign: "center",
                    color: Styles.Color.light10,
                    children: "Currently there are no members assigned to this domain team."
                  }), filteredWorkspaceMemberViewModels.length !== 0 && filteredMemberViewModels.length === 0 ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.marginBottom(Css.px(-16)),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        textAlign: "center",
                        color: Styles.Color.light10,
                        children: "Would you like to add a workspace member we did find to this domain?"
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), Belt_Array.mapU(filteredWorkspaceMemberViewModels, (function (workspaceUserToAdd) {
                          var info = DomainDetails__Utils.getValueInOrderOfImportance(Caml_option.nullable_to_opt(workspaceUserToAdd.name), Caml_option.nullable_to_opt(workspaceUserToAdd.email));
                          var email = workspaceUserToAdd.email;
                          return React.createElement("div", {
                                      key: workspaceUserToAdd.id,
                                      className: Curry._1(Css.merge, {
                                            hd: memberContainer,
                                            tl: {
                                              hd: workspaceMemberContainer,
                                              tl: /* [] */0
                                            }
                                          })
                                    }, React.createElement(ProfilePhoto.FromUserId.make, {
                                          id: workspaceUserToAdd.id,
                                          size: 40
                                        }), React.createElement("div", undefined, React.createElement($$Text.make, {
                                              size: "Small",
                                              weight: "Semi",
                                              children: info !== undefined ? info : "Missing information"
                                            }), React.createElement($$Text.make, {
                                              size: "Small",
                                              color: Styles.Color.light10,
                                              children: (email == null) ? "" : email
                                            })), React.createElement(Button.make, {
                                          label: "Add to team",
                                          onClick: (function (param) {
                                              handleToggleDomainMember(workspaceUserToAdd, true);
                                            }),
                                          style: "outline",
                                          singleLine: true
                                        }));
                        }))) : null);
}

function ManageMembersModal(Props) {
  var domain = Props.domain;
  var opened = Props.opened;
  var onClose = Props.onClose;
  var viewerIsDomainMember = Props.viewerIsDomainMember;
  return React.createElement(Mantine.Modal.make, {
              closeOnClickOutside: true,
              onClose: onClose,
              opened: opened,
              size: {
                NAME: "num",
                VAL: 600
              },
              title: "Domain team members",
              withCloseButton: true,
              withinPortal: true,
              zIndex: Styles.ZIndex.aboveAll,
              overlayStyles: {
                hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light12, 0.2)),
                tl: /* [] */0
              },
              titleStyles: {
                hd: Css.fontWeight(Styles.FontWeight.semi),
                tl: /* [] */0
              },
              yOffset: 300,
              children: React.createElement(ManageMembersModal$Content, {
                    domain: domain,
                    opened: opened,
                    viewerIsDomainMember: viewerIsDomainMember
                  })
            });
}

var make = ManageMembersModal;

export {
  make ,
}
/* memberContainer Not a pure module */
