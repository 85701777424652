// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as BranchAudit from "./BranchAudit.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ObjectsDiff from "./ObjectsDiff.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as DiffComments from "./DiffComments.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as DiffComponents from "./DiffComponents.mjs";
import * as MergeConflicts from "./MergeConflicts.mjs";
import * as BranchActionBar from "./BranchActionBar.mjs";
import * as BranchActionBox from "./BranchActionBox.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as ObjectActivityLog from "./ObjectActivityLog.mjs";
import * as PullMasterWarning from "./PullMasterWarning.mjs";

function DiffView(Props) {
  var branchStatus = Props.branchStatus;
  var branchName = Props.branchName;
  var mergeWarnings = Props.mergeWarnings;
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchId = Props.branchId;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var masterSinceOpenedDiff = Props.masterSinceOpenedDiff;
  var latestMasterAction = Props.latestMasterAction;
  var actions = Props.actions;
  var mergeConflicts = Props.mergeConflicts;
  var branchOpenAction = Props.branchOpenAction;
  var role = Props.role;
  var actionDiff = Props.actionDiff;
  var openBranches = Props.openBranches;
  var sourceUpdates = Props.sourceUpdates;
  var changes = Props.changes;
  var branchDiffStatus = Props.branchDiffStatus;
  var schemaRoute = RouterStore.Schema.useSchemaRoute();
  var user = ViewerContext.use();
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var goToCommentId;
  if (typeof schemaRoute === "object" && schemaRoute.NAME === "diff") {
    var match = schemaRoute.VAL;
    goToCommentId = typeof match === "object" && match.NAME === "comment" ? match.VAL : undefined;
  } else {
    goToCommentId = undefined;
  }
  var branchCreatorId = branchOpenAction.createdBy;
  var tmp;
  var exit = 0;
  if (mergeWarnings) {
    if (typeof branchStatus !== "object") {
      exit = 1;
    } else {
      switch (branchStatus.TAG) {
        case "Merged" :
        case "Closed" :
            tmp = null;
            break;
        default:
          exit = 1;
      }
    }
  } else {
    tmp = null;
  }
  if (exit === 1) {
    var tmp$1 = {
      branchStatus: branchStatus,
      fromModel: masterModel,
      toModel: branchModel,
      actions: mergeWarnings,
      branchId: branchId,
      events: branchModel.events
    };
    if (goToCommentId !== undefined) {
      tmp$1.goToCommentId = goToCommentId;
    }
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.padding(Css.px(15)),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Styles.Color.orange),
                  tl: {
                    hd: Css.borderRadius(Styles.Border.radius),
                    tl: {
                      hd: Css.width(Css.pct(100)),
                      tl: {
                        hd: Css.maxWidth({
                              NAME: "add",
                              VAL: [
                                DiffComponents.maxWidthPx,
                                Css.px((DiffComments.commentsWidth + 20 | 0) + 20 | 0)
                              ]
                            }),
                        tl: {
                          hd: Css.marginTop(Css.px(20)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              })
        }, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.color(Styles.Color.orange),
                    tl: {
                      hd: Css.fontWeight(Styles.FontWeight.semi),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.large),
                        tl: /* [] */0
                      }
                    }
                  })
            }, "Discarded Changes"), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.fontSize(Styles.FontSize.regular),
                    tl: {
                      hd: Css.fontWeight(Styles.FontWeight.regular),
                      tl: {
                        hd: Css.color(Styles.Color.light12),
                        tl: {
                          hd: Css.padding2(Css.px(10), Css.px(0)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, "Items you made changes to on this branch have been archived on the main branch. These changes will be discarded unless you unarchive the items on main before merging this branch."), React.createElement(ObjectsDiff.make, tmp$1));
  }
  var tmp$2;
  if (typeof branchStatus !== "object") {
    tmp$2 = null;
  } else {
    switch (branchStatus.TAG) {
      case "Merged" :
      case "Closed" :
          tmp$2 = null;
          break;
      default:
        tmp$2 = React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.maxWidth(DiffComponents.maxWidthPx),
                    tl: /* [] */0
                  })
            }, React.createElement(PullMasterWarning.make, {
                  schemaId: workspace.id,
                  masterModel: masterModel,
                  masterModelBeforeBranch: masterModelBeforeBranch,
                  masterSinceOpenedDiff: masterSinceOpenedDiff,
                  latestMasterAction: latestMasterAction,
                  branchId: branchId,
                  branchStatus: branchStatus,
                  branchPointerActionId: Belt_Option.map(branchModel.branchPointer, (function (branchPointer) {
                          return branchPointer[0];
                        })),
                  role: role,
                  events: branchModel.events
                }));
    }
  }
  var tmp$3;
  tmp$3 = typeof branchStatus !== "object" || branchStatus.TAG !== "Merged" ? masterModel : masterModelBeforeBranch;
  var tmp$4 = {
    branchStatus: branchStatus,
    fromModel: tmp$3,
    toModel: branchModel,
    actions: actions,
    branchId: branchId,
    events: branchModel.events
  };
  if (goToCommentId !== undefined) {
    tmp$4.goToCommentId = goToCommentId;
  }
  var tmp$5;
  if (actionDiff) {
    var tmp$6;
    var exit$1 = 0;
    if (mergeConflicts && (typeof branchStatus !== "object" || branchStatus.TAG !== "Merged")) {
      exit$1 = 1;
    } else {
      tmp$6 = null;
    }
    if (exit$1 === 1) {
      tmp$6 = React.createElement(MergeConflicts.make, {
            mergeConflicts: mergeConflicts,
            mode: "MergeBranch"
          });
    }
    var tmp$7 = {
      branchId: branchId,
      threadId: branchId,
      itemType: "Branch",
      itemName: branchName,
      events: branchModel.events
    };
    if (goToCommentId !== undefined) {
      tmp$7.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp$5 = React.createElement("div", undefined, tmp$6, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingTop(Css.px(16)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(32)),
                      tl: {
                        hd: Css.paddingRight(Css.px(8)),
                        tl: {
                          hd: Css.maxWidth(DiffComponents.maxWidthPx),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(BranchAudit.make, {
                  branchModelWithPulledMaster: branchModelWithPulledMaster,
                  branchStatus: branchStatus,
                  user: user,
                  sourceUpdates: sourceUpdates
                })), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.position("relative"),
                      tl: {
                        hd: Css.margin2(Css.px(20), Css.px(0)),
                        tl: {
                          hd: Css.zIndex(1),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(DiffComponents.maxWidthPx),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(BranchActionBox.make, {
                      actionDiff: actionDiff,
                      actions: actions,
                      branchCreatorId: branchCreatorId,
                      branchId: branchId,
                      branchDiffStatus: branchDiffStatus,
                      branchName: branchName,
                      branchModel: branchModel,
                      branchModelWithPulledMaster: branchModelWithPulledMaster,
                      branchStatus: branchStatus,
                      changes: changes,
                      masterModel: masterModel,
                      masterModelBeforeBranch: masterModelBeforeBranch,
                      mergeConflicts: mergeConflicts,
                      role: role,
                      sourceUpdates: sourceUpdates,
                      masterModelLatestAction: latestMasterAction
                    })), React.createElement(DiffComments.make, tmp$7)), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingTop(Css.px(16)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(32)),
                      tl: {
                        hd: Css.maxWidth(DiffComponents.maxWidthPx),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(ObjectActivityLog.make, {
                  object_: {
                    NAME: "Branch",
                    VAL: [
                      branchId,
                      branchName
                    ]
                  },
                  objectId: branchId,
                  currentBranch: {
                    NAME: "Branch",
                    VAL: branchId
                  },
                  showComments: false,
                  openBranches: openBranches
                })));
  } else {
    tmp$5 = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.fontWeight(Styles.FontWeight.semi),
                tl: {
                  hd: Css.color(Styles.Color.light10),
                  tl: {
                    hd: Css.padding(Css.px(25)),
                    tl: /* [] */0
                  }
                }
              })
        }, "No changes made.");
  }
  return React.createElement(React.Fragment, undefined, RoleUtils.canEdit(role) && actionDiff !== /* [] */0 ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("sticky"),
                          tl: {
                            hd: Css.top(Css.zero),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(BranchActionBar.make, {
                        actionDiff: actionDiff,
                        actions: actions,
                        branchCreatorId: branchCreatorId,
                        branchDiffStatus: branchDiffStatus,
                        branchId: branchId,
                        branchModel: branchModel,
                        branchModelWithPulledMaster: branchModelWithPulledMaster,
                        branchName: branchName,
                        branchStatus: branchStatus,
                        changes: changes,
                        masterModel: masterModel,
                        masterModelBeforeBranch: masterModelBeforeBranch,
                        mergeConflicts: mergeConflicts,
                        masterModelLatestAction: latestMasterAction,
                        role: role,
                        sourceUpdates: sourceUpdates
                      })) : null, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.width(Css.pct(100)),
                            tl: {
                              hd: Css.overflow("auto"),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.flexGrow(1.0),
                            tl: {
                              hd: Css.maxWidth(Css.pct(100.0)),
                              tl: {
                                hd: Css.paddingRight(Css.px(20)),
                                tl: {
                                  hd: Css.paddingLeft(Css.px(20)),
                                  tl: {
                                    hd: Css.margin4(Css.px(0), "auto", Css.px(0), "auto"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }),
                      id: "branch-tour-changes"
                    }, tmp, tmp$2, React.createElement(ObjectsDiff.make, tmp$4), tmp$5)));
}

var make = DiffView;

export {
  make ,
}
/* Css Not a pure module */
