// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Quill from "./externals/quill.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as DidMount from "./DidMount.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as UserBlot from "./UserBlot.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as EventBlot from "./EventBlot.mjs";
import * as Timestamp from "./Timestamp.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconBranch from "./IconBranch.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as UsersStore from "./UsersStore.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as ProfilePhoto from "./ProfilePhoto.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as Server from "react-dom/server";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import QuillDeltaToHtml from "quill-delta-to-html";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import CopyTextToClipboard from "copy-text-to-clipboard";

function handleCommentSeen(comment, eventId, schemaBundle, itemType, threadId, viewerId, itemName, authorEmail) {
  AnalyticsRe.commentSeen(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, comment.id, Belt_Option.getWithDefault(eventId, "N/A"), itemType === "Property" ? threadId : undefined, comment.authorId, itemName, itemType === "Integration" ? "Integration" : (
          itemType === "Property" ? "Property" : (
              itemType === "Event" ? "Event" : (
                  itemType === "PropertyGroup" ? "PropertyGroup" : (
                      itemType === "Goal" ? "Goal" : (
                          itemType === "InspectorIssue" ? "InspectorIssue" : (
                              itemType === "Metric" ? "Metric" : "Branch"
                            )
                        )
                    )
                )
            )
        ), viewerId === comment.authorId, Belt_Option.isSome(Belt_Array.getByU(JSON.parse(comment.content).ops, (function (op) {
                  var mention = Belt_Option.flatMap(Caml_option.undefined_to_opt(op.insert), (function (prim) {
                          return Caml_option.undefined_to_opt(prim["user-mention"]);
                        }));
                  if (mention !== undefined) {
                    return Caml_option.valFromOption(mention).id === viewerId;
                  } else {
                    return false;
                  }
                }))), authorEmail, undefined, undefined, schemaBundle.branchId, schemaBundle.schemaId);
}

function handleDeleteComment(schemaId, threadId, comment, schemaBundle, eventId, itemType, itemName, sendActions, userId, authorEmail) {
  var deleteCommentBatch = Firebase.app().firestore().batch();
  var commentId = comment.id;
  var commentRef = Firebase.app().firestore().collection("schemas").doc(schemaId).collection("comments").doc(threadId).collection("items").doc(commentId);
  deleteCommentBatch.delete(commentRef);
  var action_1 = [
    threadId,
    commentId
  ];
  var action = {
    NAME: "DeleteComment",
    VAL: action_1
  };
  var actionContext = {
    commentId: commentId
  };
  Curry.app(sendActions, [
        undefined,
        false,
        Caml_option.some(deleteCommentBatch),
        undefined,
        undefined,
        undefined,
        undefined,
        (function (branch) {
            var userMentions = Belt_Array.keepMapU(JSON.parse(comment.content).ops, (function (op) {
                    return Belt_Option.flatMap(Caml_option.undefined_to_opt(op.insert), (function (prim) {
                                  return Caml_option.undefined_to_opt(prim["user-mention"]);
                                }));
                  }));
            var eventMentions = Belt_Array.keepMapU(JSON.parse(comment.content).ops, (function (op) {
                    return Belt_Option.flatMap(Caml_option.undefined_to_opt(op.insert), (function (prim) {
                                  return Caml_option.undefined_to_opt(prim["event-mention"]);
                                }));
                  }));
            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
            AnalyticsRe.commentDeleted(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, comment.id, Belt_Option.getWithDefault(eventId, "N/A"), itemType === "Property" ? threadId : undefined, itemName, itemType === "Integration" ? "Integration" : (
                    itemType === "Property" ? "Property" : (
                        itemType === "Event" ? "Event" : (
                            itemType === "PropertyGroup" ? "PropertyGroup" : (
                                itemType === "Goal" ? "Goal" : (
                                    itemType === "InspectorIssue" ? "InspectorIssue" : (
                                        itemType === "Metric" ? "Metric" : "Branch"
                                      )
                                  )
                              )
                          )
                      )
                  ), userId, userMentions.length, eventMentions.length, Belt_Array.map(userMentions, (function (prim) {
                        return prim.id;
                      })), Belt_Array.map(eventMentions, (function (prim) {
                        return prim.id;
                      })), Belt_Array.map(userMentions, (function (prim) {
                        return prim.value;
                      })), Belt_Array.map(eventMentions, (function (prim) {
                        return prim.value;
                      })), authorEmail, itemType === "Goal" ? threadId : undefined, itemType === "Integration" ? threadId : undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
          }),
        undefined,
        [[
            action,
            actionContext
          ]]
      ]);
}

function getLinkToCommentId(comment) {
  var match = Router.getStaticRoute();
  var currentDrawerItem = Belt_List.head(match.drawerItems);
  var commentId = comment.id;
  var getNewDrawerItem = function (currentDrawerItem) {
    if (currentDrawerItem === undefined) {
      return ;
    }
    if (typeof currentDrawerItem !== "object") {
      return ;
    }
    var variant = currentDrawerItem.NAME;
    if (variant !== "branchPreview") {
      if (variant === "propertyGroup") {
        return {
                NAME: "propertyGroup",
                VAL: [
                  currentDrawerItem.VAL[0],
                  commentId
                ]
              };
      } else if (variant === "event") {
        return {
                NAME: "event",
                VAL: [
                  currentDrawerItem.VAL[0],
                  commentId,
                  undefined,
                  false
                ]
              };
      } else if (variant === "inspectorIssue") {
        return {
                NAME: "inspectorIssue",
                VAL: [
                  currentDrawerItem.VAL[0],
                  commentId
                ]
              };
      } else if (variant === "metric") {
        return {
                NAME: "metric",
                VAL: [
                  currentDrawerItem.VAL[0],
                  commentId,
                  false
                ]
              };
      } else if (variant === "integration") {
        return {
                NAME: "integration",
                VAL: [
                  currentDrawerItem.VAL[0],
                  commentId
                ]
              };
      } else if (variant === "property") {
        return {
                NAME: "property",
                VAL: [
                  currentDrawerItem.VAL[0],
                  commentId
                ]
              };
      } else if (variant === "category") {
        return {
                NAME: "category",
                VAL: [
                  currentDrawerItem.VAL[0],
                  commentId
                ]
              };
      } else {
        return ;
      }
    }
    var match = currentDrawerItem.VAL;
    var newDrawerItem = getNewDrawerItem(match[1]);
    if (newDrawerItem !== undefined) {
      return {
              NAME: "branchPreview",
              VAL: [
                match[0],
                newDrawerItem
              ]
            };
    }
    
  };
  var newDrawerItems = Belt_Option.mapWithDefault(getNewDrawerItem(currentDrawerItem), /* [] */0, (function (drawerItem) {
          return {
                  hd: drawerItem,
                  tl: /* [] */0
                };
        }));
  return Router.getOrigin() + Curry._4(Router.Link.get, match.baseRoute, match.branch, match.schemaRoute, newDrawerItems);
}

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: /* [] */0
        }
      }
    });

function Comment$Base(Props) {
  var comment = Props.comment;
  var events = Props.events;
  var userId = Props.userId;
  var eventId = Props.eventId;
  var threadId = Props.threadId;
  var itemType = Props.itemType;
  var itemName = Props.itemName;
  var isActive = Props.isActive;
  var link = Props.link;
  var branchId = Props.branchId;
  var openBranches = Props.openBranches;
  var workspace = WorkspaceContext.use();
  var containerRef = {
    contents: undefined
  };
  var schemaBundle = SchemaBundleContext.use();
  var userState = UsersStore.useUser(comment.authorId);
  var sendActions = SendActionsContext.use();
  var globalSend = GlobalSendContext.use();
  React.useEffect((function (param) {
          var match = containerRef.contents;
          if (isActive && match !== undefined) {
            Caml_option.valFromOption(match).scrollIntoView();
          }
          
        }), [isActive]);
  var tmp;
  if (typeof userState === "object") {
    var author = userState.VAL;
    var match = Belt_List.getByU(events, (function ($$event) {
            return Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(comment.eventId), false, (function (id) {
                          return id === $$event.id;
                        }));
          }));
    var maybeOpenBranch = Belt_List.getByU(openBranches, (function (param) {
            return param[0] === Belt_Option.getWithDefault(branchId, "master");
          }));
    tmp = React.createElement("div", {
          className: headerStyles
        }, React.createElement(DidMount.make, {
              didMount: (function (param) {
                  handleCommentSeen(comment, eventId, schemaBundle, itemType, threadId, userId, itemName, Caml_option.nullable_to_opt(author.email));
                })
            }), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.cursor("pointer"),
                            tl: /* [] */0
                          }
                        }
                      }),
                  onClick: (function (param) {
                      Curry._1(globalSend, {
                            TAG: "OpenModal",
                            _0: {
                              NAME: "Profile",
                              VAL: author.id
                            }
                          });
                    })
                }, React.createElement(ProfilePhoto.make, {
                      user: author
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginLeft(Css.px(8)),
                            tl: {
                              hd: Css.marginTop(Css.px(-1)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          children: AvoConfig.getUserDisplayName(author)
                        }))), itemType === "Property" && match !== undefined ? React.createElement(Link.make, {
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "event",
                          VAL: [
                            match.id,
                            undefined,
                            undefined,
                            false
                          ]
                        }),
                    className: Curry._1(Css.style, {
                          hd: Css.fontStyle("italic"),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.color(Styles.Color.light10),
                              tl: /* [] */0
                            }
                          }
                        }),
                    children: React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.maxWidth(Css.px(150)),
                                tl: {
                                  hd: Css.overflow("hidden"),
                                  tl: {
                                    hd: Css.textOverflow("ellipsis"),
                                    tl: {
                                      hd: Css.whiteSpace("nowrap"),
                                      tl: {
                                        hd: Css.padding2(Css.px(0), Css.px(5)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              })
                        }, "in %s" + match.name)
                  }) : null, React.createElement(Link.make, {
                  path: Curry._1(link, comment.id),
                  children: React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.paddingLeft(Css.px(5)),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.small),
                                  tl: {
                                    hd: Css.color(Styles.Color.light10),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, React.createElement(Timestamp.make, {
                            date: comment.createdAt
                          }), maybeOpenBranch !== undefined ? React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.regular),
                                      tl: {
                                        hd: Css.flexShrink(0.0),
                                        tl: {
                                          hd: Css.marginLeft(Css.px(3)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  })
                            }, "on", React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.margin4(Css.px(0), Css.px(2), Css.px(0), Css.px(5)),
                                        tl: /* [] */0
                                      })
                                }, React.createElement(IconBranch.make, {
                                      size: 8,
                                      color: Styles.Color.light08
                                    })), maybeOpenBranch[1]) : null)
                })), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginRight(Css.px(15)),
                    tl: /* [] */0
                  })
            }, React.createElement(ContextMenu.make, {
                  options: Belt_Array.concat([{
                          NAME: "Option",
                          VAL: {
                            label: "Copy URL",
                            onClick: (function (param) {
                                CopyTextToClipboard(getLinkToCommentId(comment));
                              })
                          }
                        }], comment.authorId === userId ? [{
                            NAME: "Option",
                            VAL: {
                              label: "Delete comment",
                              onClick: (function (param) {
                                  handleDeleteComment(workspace.id, threadId, comment, schemaBundle, eventId, itemType, itemName, sendActions, userId, Caml_option.nullable_to_opt(author.email));
                                })
                            }
                          }] : [])
                })));
  } else {
    tmp = userState === "NotFound" ? Pervasives.failwith("User not found") : React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: /* [] */0
                  }
                })
          }, React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.marginTop(Css.px(6)),
                      tl: {
                        hd: Css.marginRight(Css.px(8)),
                        tl: /* [] */0
                      }
                    })
              }, React.createElement(ProfilePhoto.Base.make, {
                    email: undefined
                  })), React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.marginTop(Css.px(-1)),
                      tl: {
                        hd: Css.marginRight(Css.px(5)),
                        tl: /* [] */0
                      }
                    })
              }, React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    children: "..."
                  })), React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.small),
                        tl: {
                          hd: Css.color(Styles.Color.light10),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement($$Text.make, {
                    size: "Small",
                    color: Styles.Color.light11,
                    children: React.createElement(Timestamp.make, {
                          date: comment.createdAt
                        })
                  })));
  }
  var converter = new QuillDeltaToHtml(JSON.parse(comment.content).ops);
  return React.createElement("div", {
              ref: (function (rootRef) {
                  containerRef.contents = (rootRef == null) ? undefined : Caml_option.some(rootRef);
                }),
              className: Curry._1(Css.style, {
                    hd: Css.padding4(Css.px(10), Css.px(0), Css.px(10), Css.px(10)),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.backgroundColor(isActive ? Styles.Color.lightBlue : "transparent"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(0), Css.px(0)),
                        tl: /* [] */0
                      })
                }, tmp), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding4(Css.px(3), Css.px(17), Css.px(0), Css.px(36)),
                        tl: {
                          hd: Css.maxWidth(Css.pct(100)),
                          tl: {
                            hd: Css.wordBreak("normal"),
                            tl: {
                              hd: Css.wordWrap("breakWord"),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.regular),
                                tl: {
                                  hd: Css.selector(" img", {
                                        hd: Css.maxWidth(Css.pct(100)),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: Css.selector(" p", {
                                          hd: Css.margin(Css.px(0)),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  id: "comment-content"
                }, (converter.renderCustomWith(function (customOp) {
                        var insert = customOp.insert;
                        if (insert !== undefined) {
                          if (insert.type === "event-mention") {
                            var value = insert.value;
                            var eventId = value.id;
                            return Server.renderToString(React.createElement(EventBlot.make, {
                                            eventId: eventId,
                                            schemaId: value.schemaId,
                                            eventName: Belt_Option.mapWithDefault(Belt_List.getByU(events, (function ($$event) {
                                                        return $$event.id === eventId;
                                                      })), "[DELETED] " + value.value, (function ($$event) {
                                                    return $$event.name;
                                                  }))
                                          }));
                          }
                          if (insert.type === "user-mention") {
                            return Server.renderToString(React.createElement(UserBlot.make, {
                                            userName: insert.value.value
                                          }));
                          }
                          
                        }
                        var element = document.createElement("div");
                        element.id = "unknown-blob";
                        return element.outerHTML;
                      }), React.createElement("div", {
                        dangerouslySetInnerHTML: {
                          __html: Quill.toHTML(JSON.parse(comment.content), events, undefined)
                        }
                      }))));
}

var Base = {
  handleCommentSeen: handleCommentSeen,
  handleDeleteComment: handleDeleteComment,
  getLinkToCommentId: getLinkToCommentId,
  headerStyles: headerStyles,
  make: Comment$Base
};

function $$Comment(Props) {
  var threadId = Props.threadId;
  var itemType = Props.itemType;
  var itemName = Props.itemName;
  var commentId = Props.commentId;
  var events = Props.events;
  var userId = Props.userId;
  var eventId = Props.eventId;
  var goToId = Props.goToId;
  var action = Props.action;
  var link = Props.link;
  var openBranches = Props.openBranches;
  var workspace = WorkspaceContext.use();
  var comment = FirebaseFetcherHooks.useComment(workspace.id, threadId, commentId);
  if (typeof comment !== "object") {
    return null;
  }
  var tmp = {
    comment: comment.VAL,
    events: events,
    userId: userId,
    threadId: threadId,
    itemType: itemType,
    itemName: itemName,
    isActive: goToId !== undefined ? goToId === action.id : false,
    link: link,
    openBranches: openBranches
  };
  if (eventId !== undefined) {
    tmp.eventId = Caml_option.valFromOption(eventId);
  }
  var tmp$1 = Caml_option.undefined_to_opt(action.branchId);
  if (tmp$1 !== undefined) {
    tmp.branchId = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Comment$Base, tmp);
}

var make = $$Comment;

export {
  Base ,
  make ,
}
/* headerStyles Not a pure module */
