// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as CmdK from "../../CmdK.mjs";
import * as Icon from "../../Icon.mjs";
import * as Kbar from "kbar";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Portal from "../../Portal.mjs";
import * as Styles from "../../styles.mjs";
import * as Process from "../../Process.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Dom_storage from "rescript/lib/es6/dom_storage.js";
import * as React$1 from "motion/react";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as FirebaseFetcherHooks from "../../FirebaseFetcherHooks.mjs";
import * as ReactQueryDevtools from "@tanstack/react-query-devtools";

var storageKey = "trq-devtools";

function ReactQuery__DevTools(Props) {
  var isInitiallyActive = Caml_obj.equal(Dom_storage.getItem(storageKey, sessionStorage), "true");
  var match = React.useState(function (param) {
        return isInitiallyActive;
      });
  var setShowReactQueryDevTools = match[1];
  var showReactQueryDevTools = match[0];
  var isSuperAdmin = FirebaseFetcherHooks.isSuperUser();
  var isDev = Process.getEnv() === "development";
  React.useEffect((function (param) {
          Dom_storage.setItem(storageKey, showReactQueryDevTools ? "true" : "false", sessionStorage);
        }), [showReactQueryDevTools]);
  Kbar.useRegisterActions(isSuperAdmin && isDev ? [Curry.app(CmdK.Action.t, [
                "trq_devtools",
                {
                  TAG: "RootItem",
                  _0: (
                    showReactQueryDevTools ? "Hide" : "Show"
                  ) + " React Query Devtools"
                },
                undefined,
                undefined,
                undefined,
                undefined,
                (function (param) {
                    Curry._1(setShowReactQueryDevTools, (function (isOpen) {
                            return !isOpen;
                          }));
                  }),
                undefined,
                undefined
              ])] : [], [
        isSuperAdmin,
        showReactQueryDevTools,
        isDev
      ]);
  if (isDev) {
    return React.createElement(Portal.make, {
                children: React.createElement(React$1.AnimatePresence, {
                      children: showReactQueryDevTools ? React.createElement(React$1.motion.div, {
                              id: "react-query-dev-tools",
                              animate: {
                                opacity: 1,
                                y: 0
                              },
                              transition: {
                                duration: 0.3
                              },
                              initial: {
                                opacity: 0,
                                y: 50
                              },
                              exit: {
                                opacity: 0,
                                y: 50
                              },
                              className: Curry._1(Css.style, {
                                    hd: Css.position("fixed"),
                                    tl: {
                                      hd: Css.bottom("zero"),
                                      tl: {
                                        hd: Css.left("zero"),
                                        tl: {
                                          hd: Css.right("zero"),
                                          tl: {
                                            hd: Css.width(Css.pct(100.0)),
                                            tl: {
                                              hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                              tl: {
                                                hd: Css.backgroundColor("transparent"),
                                                tl: {
                                                  hd: Css.filter({
                                                        hd: {
                                                          NAME: "dropShadow",
                                                          VAL: [
                                                            Css.px(0),
                                                            Css.px(0),
                                                            Css.px(8),
                                                            Styles.Color.light07
                                                          ]
                                                        },
                                                        tl: /* [] */0
                                                      }),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }),
                              key: "react-query-dev-tools",
                              children: null
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.justifyContent("flexEnd"),
                                          tl: {
                                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light07),
                                            tl: {
                                              hd: Css.backgroundColor("transparent"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement("button", {
                                      className: Curry._1(Css.merge, {
                                            hd: Curry._1(Css.style, Styles.buttonReset),
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.backgroundColor(Styles.Color.white),
                                                    tl: {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.marginRight(Css.px(16)),
                                                        tl: {
                                                          hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
                                                          tl: {
                                                            hd: Css.borderBottomWidth("zero"),
                                                            tl: {
                                                              hd: Css.borderTopLeftRadius(Css.px(4)),
                                                              tl: {
                                                                hd: Css.borderTopRightRadius(Css.px(4)),
                                                                tl: {
                                                                  hd: Css.padding(Css.px(4)),
                                                                  tl: {
                                                                    hd: Icon.color(Styles.Color.light06),
                                                                    tl: {
                                                                      hd: Css.hover({
                                                                            hd: Icon.styles({
                                                                                  hd: Css.transition({
                                                                                        NAME: "ms",
                                                                                        VAL: Styles.Duration.$$default
                                                                                      }, undefined, undefined, "all"),
                                                                                  tl: {
                                                                                    hd: Css_Legacy_Core.SVG.fill(Styles.Color.light11),
                                                                                    tl: /* [] */0
                                                                                  }
                                                                                }),
                                                                            tl: /* [] */0
                                                                          }),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      onClick: (function (param) {
                                          Curry._1(setShowReactQueryDevTools, (function (param) {
                                                  return false;
                                                }));
                                        })
                                    }, React.createElement(Icon.make, {
                                          type_: "close",
                                          size: "small"
                                        }))), React.createElement(ReactQueryDevtools.ReactQueryDevtoolsPanel, {
                                  onClose: (function (param) {
                                      Curry._1(setShowReactQueryDevTools, (function (param) {
                                              return false;
                                            }));
                                    })
                                })) : null
                    })
              });
  } else {
    return null;
  }
}

var make = ReactQuery__DevTools;

export {
  make ,
}
/* Css Not a pure module */
