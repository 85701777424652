// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Title from "./Title.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Issues from "../../shared/models/Issues.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as DateFns from "./DateFns.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as UsersStore from "./UsersStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RouterStore from "./RouterStore.mjs";
import * as AvoEngagement from "./avoEngagement/AvoEngagement.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as InspectorStats from "./inspector/InspectorStats.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as InspectorEmptyState from "./inspector/InspectorEmptyState.mjs";
import * as InspectorIssuesStore from "./inspector/InspectorIssuesStore.mjs";
import * as ConfigureAlertsButton from "./inspector/ConfigureAlertsButton.mjs";
import * as InspectorIssuesFilterHooks from "./inspector/InspectorIssuesFilterHooks.mjs";
import * as InspectorIssuesView__Table from "./InspectorIssuesView__Table.mjs";
import * as InspectorIssuesView__Buttons from "./InspectorIssuesView__Buttons.mjs";
import * as InspectorIssuesView__SavedViewName from "./InspectorIssuesView__SavedViewName.mjs";
import * as InspectorIssuesView__WhatAreIssues from "./InspectorIssuesView__WhatAreIssues.mjs";

function InspectorIssuesView$CreatedBy(Props) {
  var userId = Props.userId;
  var createdAt = Props.createdAt;
  var user = UsersStore.useUser(userId);
  var createdAt$1 = DateFns.formatDistance(false, undefined, createdAt, new Date());
  if (typeof user === "object" && user.NAME === "Success") {
    return React.createElement($$Text.make, {
                element: "Span",
                size: "Small",
                color: Styles.Color.light10,
                children: null
              }, "Created by ", React.createElement($$Text.make, {
                    element: "Span",
                    weight: "Semi",
                    children: AvoConfig.getUserDisplayName(user.VAL)
                  }), " " + createdAt$1 + " ago");
  } else {
    return null;
  }
}

function InspectorIssuesView$DraftBadge(Props) {
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.borderRadius(Css.px(10)),
                    tl: {
                      hd: Css.padding2(Css.px(2), Css.px(8)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.lightGreen),
                        tl: {
                          hd: Css.color(Styles.Color.green),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Tiny",
                  weight: "Semi",
                  children: "DRAFT"
                }));
}

var header = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.flexShrink(0.0),
          tl: {
            hd: Css.paddingRight(Css.px(24)),
            tl: {
              hd: Css.paddingLeft(Css.px(40)),
              tl: {
                hd: Css.height(Css.px(64)),
                tl: {
                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var subHeader = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.flexShrink(0.0),
          tl: {
            hd: Css.paddingRight(Css.px(24)),
            tl: {
              hd: Css.paddingLeft(Css.px(40)),
              tl: {
                hd: Css.marginBottom(Css.px(20)),
                tl: {
                  hd: Css.justifyContent("spaceBetween"),
                  tl: {
                    hd: Css.paddingTop(Css.px(20)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var subHeaderTitle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("baseline"),
          tl: /* [] */0
        }
      }
    });

var toolbar = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.gap(Css.px(8)),
          tl: /* [] */0
        }
      }
    });

function warningBox(backgroundColor) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("row"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("spaceBetween"),
                    tl: {
                      hd: Css.width({
                            NAME: "subtract",
                            VAL: [
                              Css.pct(100.0),
                              Css.px(48)
                            ]
                          }),
                      tl: {
                        hd: Css.backgroundColor(backgroundColor),
                        tl: {
                          hd: Css.paddingTop(Css.px(12)),
                          tl: {
                            hd: Css.paddingRight(Css.px(16)),
                            tl: {
                              hd: Css.paddingBottom(Css.px(12)),
                              tl: {
                                hd: Css.paddingLeft(Css.px(16)),
                                tl: {
                                  hd: Css.borderRadius(Styles.Border.radius),
                                  tl: {
                                    hd: Css.marginTop(Css.px(8)),
                                    tl: {
                                      hd: Css.marginRight(Css.px(24)),
                                      tl: {
                                        hd: Css.marginBottom(Css.px(16)),
                                        tl: {
                                          hd: Css.marginLeft(Css.px(24)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var warningBoxButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: $$Text.sizeStyles("Small"),
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.color(Styles.Color.light12),
                tl: {
                  hd: Css.textDecoration("underline"),
                  tl: {
                    hd: Css.fontWeight(Styles.FontWeight.semi),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$short
                          }, undefined, undefined, "background-color"),
                      tl: {
                        hd: Css.hover({
                              hd: Css.color(Styles.Color.light12),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

function InspectorIssuesView$SetupAlertsFlow(Props) {
  var match = Router.QueryParams.useStringKey("start_tour");
  var removeByReplace = match.removeByReplace;
  var value = match.value;
  var match$1 = AvoEngagement.Engagement.use("set-up-alerts");
  var intent = match$1[1];
  var activatedNow = React.useRef(false);
  React.useEffect((function (param) {
          if (Caml_obj.equal(value, "set-up-alerts")) {
            Curry._1(intent.start, undefined);
            Curry._1(removeByReplace, undefined);
            activatedNow.current = true;
          } else if (!activatedNow.current) {
            Curry._1(intent.exit, undefined);
          }
          
        }), [value]);
  return null;
}

function InspectorIssuesView(Props) {
  var model = Props.model;
  var viewerRole = Props.viewerRole;
  var schemaRoute = RouterStore.Schema.useSchemaRoute();
  var match = InspectorIssuesStore.useFilteredIssues(undefined, undefined, undefined);
  var filteredIssues = match.filteredIssues;
  var addToast = Toast.useAddToast();
  var globalSend = GlobalSendContext.use();
  var workspace = WorkspaceContext.use();
  var match$1 = InspectorIssuesFilterHooks.useIssueStatusQuery();
  var status = match$1[0];
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews();
  var match$2 = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState();
  var currentSavedView = match$2.savedView;
  var previousSavedView = Hooks.usePrevious1(currentSavedView);
  var hasExportIssuesToCSV = AppFeatureFlag.useFeatureFlag("ExportIssuesToCSV");
  var match$3 = InspectorIssuesFilterHooks.useDraftState();
  var isDraft = match$3.isDraft;
  React.useEffect((function (param) {
          if (previousSavedView !== undefined && currentSavedView === undefined && typeof savedViews === "object" && savedViews.NAME === "Loaded" && typeof schemaRoute === "object" && schemaRoute.NAME === "inspector") {
            var match = schemaRoute.VAL;
            if (typeof match === "object" && match.NAME === "savedView" && previousSavedView.id === match.VAL) {
              Curry._1(addToast, {
                    message: "\"" + previousSavedView.name + "\" was just archived",
                    toastType: "Info",
                    persist: true
                  });
              Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                        NAME: "inspector",
                        VAL: "issues"
                      }));
            }
            
          }
          
        }), [
        currentSavedView,
        savedViews
      ]);
  var inspectorStats = InspectorStats.useStatsWithCache();
  var hasSourcesWithoutInspector = Belt_List.some(model.sources, (function (source) {
          var match = InspectorStats.getSourceStatus(source, inspectorStats);
          if (typeof match !== "object" && match !== "Loading") {
            return true;
          } else {
            return false;
          }
        }));
  var hasSourcesWithInspector = Belt_List.some(model.sources, (function (source) {
          var match = InspectorStats.getSourceStatus(source, inspectorStats);
          if (typeof match !== "object" && match !== "Loading") {
            return false;
          } else {
            return true;
          }
        }));
  var inspectorSources = Belt_List.toArray(Belt_List.keep(model.sources, (function (source) {
              var match = InspectorStats.getSourceStatus(source, inspectorStats);
              if (typeof match !== "object" && match !== "Loading") {
                return false;
              } else {
                return true;
              }
            })));
  var exit = 0;
  if (typeof inspectorStats !== "object" || inspectorStats._0.length !== 0) {
    exit = 1;
  } else {
    if (!workspace.isSandbox) {
      return React.createElement(InspectorEmptyState.make, {
                  hasSourcesWithoutInspector: hasSourcesWithoutInspector,
                  hasSourcesWithInspector: hasSourcesWithInspector,
                  inspectorView: "issues"
                });
    }
    exit = 1;
  }
  if (exit === 1) {
    var tmp = {
      label: hasSourcesWithInspector ? "Add Source" : "Connect Source",
      onClick: (function (param) {
          Curry._1(globalSend, {
                TAG: "OpenModal",
                _0: hasSourcesWithoutInspector ? ({
                      NAME: "SourceSetup",
                      VAL: [
                        "inspectorSetup",
                        {
                          NAME: "inspector",
                          VAL: "events"
                        }
                      ]
                    }) : ({
                      NAME: "CreateSource",
                      VAL: "inspectorSetup"
                    })
              });
        }),
      style: "outline"
    };
    var tmp$1 = hasSourcesWithInspector ? "plus" : undefined;
    if (tmp$1 !== undefined) {
      tmp.icon = Caml_option.valFromOption(tmp$1);
    }
    var match$4 = AvoLimits.getInspectorVolumeStatus(workspace);
    var match$5 = AvoLimits.isInspectorBlockedWhenLimitExceeded(workspace);
    var tmp$2;
    if (typeof match$4 === "object") {
      var variant = match$4.NAME;
      if (variant === "ExceededThreshold") {
        var exit$1 = 0;
        if (viewerRole === "Admin" && !(match$5 || !(!AvoLimits.isInspectorBlockedWhenLimitExceeded(workspace) && viewerRole === "Admin"))) {
          tmp$2 = React.createElement("div", {
                className: warningBox(Styles.Color.lightOrange)
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("row"),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(Icon.make, {
                        type_: "warning",
                        color: Styles.Color.orange
                      }), React.createElement(Spacer.make, {
                        width: 8
                      }), React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light11,
                        children: null
                      }, React.createElement($$Text.make, {
                            element: "Span",
                            weight: "Semi",
                            children: "You’ve exceeded your event volume limit."
                          }), " Additional charges will be billed to your account.")), React.createElement("button", {
                    className: warningBoxButton,
                    onClick: (function (param) {
                        Router.push(undefined, Router.Link.getSchemaRouteLink(undefined, "billing"));
                      })
                  }, "See usage stats"));
        } else {
          exit$1 = 2;
        }
        if (exit$1 === 2) {
          tmp$2 = match$5 ? React.createElement("div", {
                  className: warningBox(Styles.Color.lightRed)
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("row"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(Icon.make, {
                          type_: "warning",
                          color: Styles.Color.red
                        }), React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.light11,
                          children: null
                        }, React.createElement($$Text.make, {
                              element: "Span",
                              weight: "Semi",
                              children: "You’ve exceeded your event volume limit."
                            }), " Inspector issues are no longer accessible.")), viewerRole === "Admin" ? React.createElement("button", {
                        className: warningBoxButton,
                        onClick: (function (param) {
                            Router.push(undefined, Router.Link.getSchemaRouteLink(undefined, "billing"));
                          })
                      }, "See usage stats") : React.createElement("button", {
                        className: warningBoxButton,
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: "OpenModal",
                                  _0: {
                                    NAME: "BillingPrompt",
                                    VAL: "InspectorVolume"
                                  }
                                });
                          })
                      }, "Learn more")) : null;
        }
        
      } else if (variant === "ReachingThreshold" && viewerRole === "Admin") {
        var percentage = String(match$4.VAL * 100.0 | 0);
        tmp$2 = React.createElement("div", {
              className: warningBox(Styles.Color.lightOrange)
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("row"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(Icon.make, {
                      type_: "warning",
                      color: Styles.Color.orange
                    }), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light11,
                      children: null
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "You’ve reached " + percentage + "% of your event volume limit."
                        }), match$5 ? " You wont be able to view issues when you exceed the limit." : " You may incur additional charges if you exceed your volume limit.")), React.createElement("button", {
                  className: warningBoxButton,
                  onClick: (function (param) {
                      Router.push(undefined, Router.Link.getSchemaRouteLink(undefined, "billing"));
                    })
                }, "See usage stats"));
      } else {
        tmp$2 = null;
      }
    } else {
      tmp$2 = null;
    }
    return React.createElement(React.Fragment, undefined, React.createElement(InspectorIssuesView$SetupAlertsFlow, {}), React.createElement("header", {
                    className: header
                  }, React.createElement(Title.make, {
                        children: "Inspector Issues",
                        size: "Tiny"
                      }), React.createElement(Spacer.make, {
                        width: 16
                      }), React.createElement(Button.make, tmp), React.createElement(Spacer.make, {
                        width: 8,
                        grow: 1.0
                      }), React.createElement(InspectorIssuesView__WhatAreIssues.make, {})), tmp$2, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.overflowX("auto"),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("column"),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, React.createElement("div", {
                        className: subHeader,
                        id: "inspector-issues-view-header"
                      }, React.createElement("div", {
                            className: subHeaderTitle
                          }, currentSavedView !== undefined ? React.createElement(InspectorIssuesView__SavedViewName.make, {
                                  view: currentSavedView,
                                  filteredIssuesCount: Curry._1(Issues.size, filteredIssues)
                                }) : (
                              isDraft ? React.createElement(InspectorIssuesView__Buttons.SaveButton.make, {
                                      target: React.createElement("button", {
                                            className: Curry._1(Css.merge, {
                                                  hd: Curry._1(Css.style, Styles.buttonReset),
                                                  tl: {
                                                    hd: Curry._1(Css.style, {
                                                          hd: Css.cursor("text"),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement($$Text.make, {
                                                element: "Em",
                                                size: "Large",
                                                weight: "Semi",
                                                color: Styles.Color.light10,
                                                children: "Untitled Draft"
                                              })),
                                      filteredIssuesCount: Curry._1(Issues.size, filteredIssues),
                                      position: "bottom",
                                      currentSavedView: currentSavedView
                                    }) : React.createElement($$Text.make, {
                                      size: "Large",
                                      weight: "Semi",
                                      color: Styles.Color.light12,
                                      children: "All issues"
                                    })
                            ), React.createElement(Spacer.make, {
                                width: 4
                              }), currentSavedView !== undefined ? React.createElement(InspectorIssuesView$CreatedBy, {
                                  userId: currentSavedView.createdBy,
                                  createdAt: currentSavedView.createdAt
                                }) : null, React.createElement(Spacer.make, {
                                width: 4
                              }), isDraft ? React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Belt_Option.isSome(currentSavedView) ? Styles.emptyStyle : Css.alignSelf("flexStart"),
                                        tl: {
                                          hd: Css.marginTop(Css.px(5)),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement(InspectorIssuesView$DraftBadge, {})) : null, hasExportIssuesToCSV ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                      width: 8
                                    }), React.createElement(InspectorIssuesView__Buttons.ExportButton.make, {
                                      status: status
                                    })) : null), React.createElement("div", {
                            className: toolbar
                          }, React.createElement("span", {
                                id: "set-up-alerts"
                              }, React.createElement(ConfigureAlertsButton.make, {
                                    globalSend: globalSend,
                                    savedView: currentSavedView,
                                    key: currentSavedView !== undefined ? currentSavedView.id : "all"
                                  })), React.createElement(InspectorIssuesView__Buttons.CopyButton.make, {
                                savedView: currentSavedView,
                                issueCount: Curry._1(Issues.size, filteredIssues)
                              }), currentSavedView !== undefined ? React.createElement(InspectorIssuesView__Buttons.DuplicateButton.make, {
                                  filteredIssuesCount: Curry._1(Issues.size, filteredIssues)
                                }) : null, React.createElement(InspectorIssuesView__Buttons.SaveButton.make, {
                                filteredIssuesCount: Curry._1(Issues.size, filteredIssues),
                                currentSavedView: currentSavedView
                              }))), React.createElement(InspectorIssuesView__Table.make, {
                        inspectorSources: inspectorSources,
                        globalSend: globalSend,
                        status: status,
                        setStatus: match$1[1],
                        viewerRole: viewerRole
                      })));
  }
  
}

var make = InspectorIssuesView;

export {
  make ,
}
/* header Not a pure module */
