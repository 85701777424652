// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Router from "./Router.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as UsersStore from "./UsersStore.mjs";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as QuillToPlainText from "../../shared/utils/QuillToPlainText.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as ObjectActivityLogAction from "./ObjectActivityLogAction.mjs";

function EventVariantActivityItem(Props) {
  var model = Props.model;
  var action = Props.action;
  var userIds = Props.userIds;
  var openBranches = Props.openBranches;
  var users = UsersStore.use(userIds);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace();
  if (typeof users !== "object") {
    return null;
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var match = action.contents;
  if (typeof match !== "object") {
    return null;
  }
  var variant = match.NAME;
  if (variant === "RemoveEventVariantTrigger") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "removed a trigger"
              });
  }
  if (variant === "AddPropertyToEventVariant") {
    var propertyId = match.VAL[1];
    var match$1 = ModelUtils.getPropertyByIdWithArchive(propertyId, model);
    if (match$1 !== undefined && match$1.TAG !== "PropertyRef") {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId,
                          undefined
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "added the property ", React.createElement("b", undefined, match$1._0.name));
    } else {
      return null;
    }
  }
  if (variant === "UpdateEventVariantTriggerSources") {
    var match$2 = match.VAL;
    var sources = match$2[2];
    var triggerId = match$2[1];
    var variantIdentifier = match$2[0];
    var sourceNames;
    sourceNames = typeof sources !== "object" ? (
        isGlobalWorkspace ? "Workspace Independent" : "Source Independent"
      ) : Belt_Array.map(Belt_Array.keepMap(sources._0, (function (sourceId) {
                    return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                  })), AvoConfig.getSourceName).join(", ");
    var match$3 = ModelUtils.getEventVariantTriggerById(model, variantIdentifier, triggerId);
    if (match$3 !== undefined) {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "eventVariant",
                        VAL: [
                          variantIdentifier,
                          triggerId
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "updated a trigger with the description ", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$3.description)), " to be triggered with ", React.createElement("b", undefined, sourceNames));
    } else {
      return null;
    }
  }
  if (variant === "ClearPropertyPinnedValueFromEventVariant") {
    var propertyId$1 = match.VAL[1];
    var match$4 = ModelUtils.getPropertyByIdWithArchive(propertyId$1, model);
    if (match$4 !== undefined && match$4.TAG !== "PropertyRef") {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId$1,
                          undefined
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "removed a pinned value from the property ", React.createElement("b", undefined, match$4._0.name));
    } else {
      return null;
    }
  }
  if (variant === "SetSourceOverrideOnVariant") {
    var match$5 = match.VAL;
    var sourceId = match$5[1];
    var source = ModelUtils.getSourceByIdWithArchive(sourceId, model);
    if (source === undefined) {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: "updated the sources this event variant is sent from "
                });
    }
    var tmp;
    var tmp$1 = match$5[2];
    tmp = typeof tmp$1 !== "object" ? React.createElement(React.Fragment, undefined, "removed the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source)), " from this variant ") : React.createElement(React.Fragment, undefined, "added the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source)), " to this variant ");
    return React.createElement(ObjectActivityLogAction.make, {
                action: action,
                users: users$1,
                path: Router.Link.addDrawerItem(undefined, {
                      NAME: "source",
                      VAL: [
                        sourceId,
                        "overview"
                      ]
                    }),
                openBranches: openBranches,
                children: tmp
              });
  }
  if (variant === "RemoveEventVariantTriggerSource") {
    var match$6 = match.VAL;
    var triggerId$1 = match$6[1];
    var variantIdentifier$1 = match$6[0];
    var match$7 = ModelUtils.getSourceByIdWithArchive(match$6[2], model);
    var match$8 = ModelUtils.getEventVariantTriggerById(model, variantIdentifier$1, triggerId$1);
    if (match$7 !== undefined && match$8 !== undefined) {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "eventVariant",
                        VAL: [
                          variantIdentifier$1,
                          triggerId$1
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "removed ", React.createElement("b", undefined, AvoConfig.getSourceName(match$7)), " as a source for the trigger with the description ", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$8.description)));
    } else {
      return null;
    }
  }
  if (variant === "CreateEventVariantTrigger") {
    var match$9 = match.VAL;
    var sources$1 = match$9[2];
    var triggerId$2 = match$9[1];
    var variantIdentifier$2 = match$9[0];
    var sourceNames$1;
    sourceNames$1 = typeof sources$1 !== "object" ? (
        isGlobalWorkspace ? "Workspace Independent" : "Source Independent"
      ) : Belt_Array.map(Belt_Array.keepMap(sources$1._0, (function (sourceId) {
                    return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                  })), AvoConfig.getSourceName).join(", ");
    var match$10 = ModelUtils.getEventVariantTriggerById(model, variantIdentifier$2, triggerId$2);
    if (match$10 !== undefined) {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "eventVariant",
                        VAL: [
                          variantIdentifier$2,
                          triggerId$2
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "created a trigger for ", React.createElement("b", undefined, sourceNames$1), " with the description ", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$10.description)));
    } else {
      return null;
    }
  }
  if (variant === "ClearSourceOverrideOnVariant") {
    var sourceId$1 = match.VAL[1];
    var source$1 = ModelUtils.getSourceByIdWithArchive(sourceId$1, model);
    if (source$1 !== undefined) {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "source",
                        VAL: [
                          sourceId$1,
                          "overview"
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "cleare the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$1)), " override on this variant");
    } else {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: "cleared an override of a source this event variant is sent from"
                });
    }
  }
  if (variant === "UpdatePropertyRegexOverrideOnEventVariant") {
    var match$11 = match.VAL;
    var maybeRegexValidationRule = match$11[2];
    var propertyId$2 = match$11[1];
    var match$12 = ModelUtils.getPropertyByIdWithArchive(propertyId$2, model);
    if (match$12 === undefined) {
      return null;
    }
    if (match$12.TAG === "PropertyRef") {
      return null;
    }
    var property = match$12._0;
    return React.createElement(ObjectActivityLogAction.make, {
                action: action,
                users: users$1,
                path: Router.Link.addDrawerItem(undefined, {
                      NAME: "property",
                      VAL: [
                        propertyId$2,
                        undefined
                      ]
                    }),
                openBranches: openBranches,
                children: maybeRegexValidationRule !== undefined ? React.createElement(React.Fragment, undefined, "set the regex constraint for the ", React.createElement("b", undefined, property.name), " to ", React.createElement("b", undefined, maybeRegexValidationRule.regex)) : React.createElement(React.Fragment, undefined, "removed the event-specific regex constraint override from the ", React.createElement("b", undefined, property.name))
              });
  }
  if (variant === "Archive") {
    var match$13 = match.VAL;
    if (typeof match$13 === "object" && match$13.NAME === "EventVariant") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: "archived the variant "
                });
    } else {
      return null;
    }
  }
  if (variant === "ClearEventVariantPropertyOverride") {
    var propertyId$3 = match.VAL[1];
    var match$14 = ModelUtils.getPropertyByIdWithArchive(propertyId$3, model);
    if (match$14 !== undefined && match$14.TAG !== "PropertyRef") {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId$3,
                          undefined
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "removed variations on the property ", React.createElement("b", undefined, match$14._0.name));
    } else {
      return null;
    }
  }
  if (variant === "UpdateEventVariantDescription") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "updated the description: \"" + match.VAL[1] + "\""
              });
  }
  if (variant === "SetPropertyAbsenceOnVariant") {
    var match$15 = match.VAL;
    var absenceOverride = match$15[2];
    var propertyId$4 = match$15[1];
    var match$16 = ModelUtils.getPropertyByIdWithArchive(propertyId$4, model);
    if (match$16 === undefined) {
      return null;
    }
    if (match$16.TAG === "PropertyRef") {
      return null;
    }
    var property$1 = match$16._0;
    var tmp$2;
    if (typeof absenceOverride !== "object") {
      tmp$2 = React.createElement(React.Fragment, undefined, "removed the absence override from the property ", React.createElement("b", undefined, property$1.name));
    } else {
      var tmp$3;
      switch (absenceOverride._0) {
        case "AlwaysSent" :
            tmp$3 = "always sent";
            break;
        case "SometimesSent" :
            tmp$3 = "sometimes sent";
            break;
        case "NeverSent" :
            tmp$3 = "never sent";
            break;
        
      }
      tmp$2 = React.createElement(React.Fragment, undefined, "set the absence of the property ", React.createElement("b", undefined, property$1.name), " to ", React.createElement("b", undefined, tmp$3));
    }
    return React.createElement(ObjectActivityLogAction.make, {
                action: action,
                users: users$1,
                path: Router.Link.addDrawerItem(undefined, {
                      NAME: "property",
                      VAL: [
                        propertyId$4,
                        undefined
                      ]
                    }),
                openBranches: openBranches,
                children: tmp$2
              });
  }
  if (variant === "OverrideEventVariantSpecificPropertyValueToBeDisallowed") {
    var match$17 = match.VAL;
    var literal = match$17[2];
    var propertyId$5 = match$17[1];
    var match$18 = ModelUtils.getPropertyByIdWithArchive(propertyId$5, model);
    if (match$18 !== undefined && !(match$18.TAG === "PropertyRef" || !(typeof literal === "object" && literal.NAME === "StringLit"))) {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId$5,
                          undefined
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "removed the allowed value \"" + literal.VAL + "\" from the property ", React.createElement("b", undefined, match$18._0.name));
    } else {
      return null;
    }
  }
  if (variant === "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources") {
    var match$19 = match.VAL;
    var literal$1 = match$19[2];
    var propertyId$6 = match$19[1];
    var match$20 = ModelUtils.getPropertyByIdWithArchive(propertyId$6, model);
    if (match$20 !== undefined && !(match$20.TAG === "PropertyRef" || !(typeof literal$1 === "object" && literal$1.NAME === "StringLit"))) {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId$6,
                          undefined
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "added the allowed value \"" + literal$1.VAL + "\" to the property ", React.createElement("b", undefined, match$20._0.name));
    } else {
      return null;
    }
  }
  if (variant === "UpdateEventVariantTriggerDescription") {
    var match$21 = match.VAL;
    return React.createElement(ObjectActivityLogAction.make, {
                action: action,
                users: users$1,
                path: Router.Link.addDrawerItem(undefined, {
                      NAME: "eventVariant",
                      VAL: [
                        match$21[0],
                        match$21[1]
                      ]
                    }),
                openBranches: openBranches,
                children: null
              }, "updated the description for the trigger ", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$21[2])));
  }
  if (variant === "AddEventVariantTriggerSource") {
    var match$22 = match.VAL;
    var triggerId$3 = match$22[1];
    var variantIdentifier$3 = match$22[0];
    var match$23 = ModelUtils.getSourceByIdWithArchive(match$22[2], model);
    var match$24 = ModelUtils.getEventVariantTriggerById(model, variantIdentifier$3, triggerId$3);
    if (match$23 !== undefined && match$24 !== undefined) {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "eventVariant",
                        VAL: [
                          variantIdentifier$3,
                          triggerId$3
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "added ", React.createElement("b", undefined, AvoConfig.getSourceName(match$23)), " as a source for the trigger with the description ", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$24.description)));
    } else {
      return null;
    }
  }
  if (variant === "CreateEventVariant") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "created the variant"
              });
  }
  if (variant === "RemovePropertyFromEventVariant") {
    var propertyId$7 = match.VAL[1];
    var match$25 = ModelUtils.getPropertyByIdWithArchive(propertyId$7, model);
    if (match$25 !== undefined && match$25.TAG !== "PropertyRef") {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId$7,
                          undefined
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "removed the property ", React.createElement("b", undefined, match$25._0.name));
    } else {
      return null;
    }
  }
  if (variant === "ClearPropertyRegexOverrideFromEventVariant") {
    var propertyId$8 = match.VAL[1];
    var match$26 = ModelUtils.getPropertyByIdWithArchive(propertyId$8, model);
    if (match$26 !== undefined && match$26.TAG !== "PropertyRef") {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId$8,
                          undefined
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "cleared the regex constraint override from the property ", React.createElement("b", undefined, match$26._0.name));
    } else {
      return null;
    }
  }
  if (variant === "UpdateEventVariantTriggerContent") {
    var match$27 = match.VAL;
    var triggerId$4 = match$27[1];
    var variantIdentifier$4 = match$27[0];
    var match$28 = ModelUtils.getEventVariantTriggerById(model, variantIdentifier$4, triggerId$4);
    if (match$28 !== undefined) {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "eventVariant",
                        VAL: [
                          variantIdentifier$4,
                          triggerId$4
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "updated content for the trigger ", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$28.description)));
    } else {
      return null;
    }
  }
  if (variant === "Unarchive") {
    var match$29 = match.VAL;
    if (typeof match$29 === "object" && match$29.NAME === "EventVariant") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: "unarchived the variant "
                });
    } else {
      return null;
    }
  }
  if (variant === "ClearEventVariantSpecificPropertyValueOverride") {
    var match$30 = match.VAL;
    var literal$2 = match$30[2];
    var propertyId$9 = match$30[1];
    var match$31 = ModelUtils.getPropertyByIdWithArchive(propertyId$9, model);
    if (match$31 !== undefined && !(match$31.TAG === "PropertyRef" || !(typeof literal$2 === "object" && literal$2.NAME === "StringLit"))) {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId$9,
                          undefined
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "cleared the override for the allowed value \"" + literal$2.VAL + "\" from the property ", React.createElement("b", undefined, match$31._0.name));
    } else {
      return null;
    }
  }
  if (variant !== "UpdatePropertyPinnedValueOnEventVariant") {
    return null;
  }
  var match$32 = match.VAL;
  var propertyId$10 = match$32[1];
  var match$33 = ModelUtils.getPropertyByIdWithArchive(propertyId$10, model);
  if (match$33 !== undefined && match$33.TAG !== "PropertyRef") {
    return React.createElement(ObjectActivityLogAction.make, {
                action: action,
                users: users$1,
                path: Router.Link.addDrawerItem(undefined, {
                      NAME: "property",
                      VAL: [
                        propertyId$10,
                        undefined
                      ]
                    }),
                openBranches: openBranches,
                children: null
              }, "pinned the property ", React.createElement("b", undefined, match$33._0.name), " to " + PropertyUtils.printPinnedValue(match$32[2]));
  } else {
    return null;
  }
}

var make = EventVariantActivityItem;

export {
  make ,
}
/* react Not a pure module */
