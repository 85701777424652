// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Changes from "../../shared/Changes.mjs";
import * as Printer from "../../model/src/Printer.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ChangesWorkerInstance from "./workers/ChangesWorkerInstance.mjs";
import * as FlattenVariantsUseCase from "../../model/src/variants/flattenVariantsUseCase.mjs";

function useChanges(fromModel, toModel, onWorkerError) {
  var match = React.useReducer((function (state, action) {
          if (typeof action === "object") {
            return {
                    changes: action.VAL,
                    status: "loaded"
                  };
          } else {
            return {
                    changes: state.changes,
                    status: "loading"
                  };
          }
        }), {
        changes: Changes.empty,
        status: "loading"
      });
  var reduceChangeState = match[1];
  var fromModelWithFlattenedEventVariants = React.useMemo((function (param) {
          return Belt_Option.map(fromModel, FlattenVariantsUseCase.flatten);
        }), [fromModel]);
  var toModelWithFlattenedEventVariants = React.useMemo((function (param) {
          return Belt_Option.map(toModel, FlattenVariantsUseCase.flatten);
        }), [toModel]);
  var fromModelWithFlattenedEventVariantsJson = React.useMemo((function (param) {
          return Belt_Option.map(fromModelWithFlattenedEventVariants, Printer.printModel);
        }), [fromModelWithFlattenedEventVariants]);
  var toModelWithFlattenedEventVariantsJson = React.useMemo((function (param) {
          return Belt_Option.map(toModelWithFlattenedEventVariants, Printer.printModel);
        }), [toModelWithFlattenedEventVariants]);
  React.useEffect((function (param) {
          if (fromModelWithFlattenedEventVariantsJson !== undefined && toModelWithFlattenedEventVariantsJson !== undefined) {
            Curry._1(reduceChangeState, "loading");
            $$Promise.$$catch(ChangesWorkerInstance.Stable.getModelChanges(fromModelWithFlattenedEventVariantsJson, toModelWithFlattenedEventVariantsJson).then(function (changes) {
                      Curry._1(reduceChangeState, {
                            NAME: "loaded",
                            VAL: changes
                          });
                    }), (function (error) {
                    Curry._1(onWorkerError, error);
                    if (fromModelWithFlattenedEventVariants !== undefined && toModelWithFlattenedEventVariants !== undefined) {
                      Curry._1(reduceChangeState, {
                            NAME: "loaded",
                            VAL: Changes.getChanges(fromModelWithFlattenedEventVariants, toModelWithFlattenedEventVariants)
                          });
                    } else {
                      console.error("Failed getChanges in client after failed webworker call");
                    }
                    return Promise.resolve();
                  }));
          }
          
        }), [
        fromModelWithFlattenedEventVariants,
        toModelWithFlattenedEventVariants
      ]);
  return match[0];
}

export {
  useChanges ,
}
/* react Not a pure module */
