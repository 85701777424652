// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Mantine from "../Mantine.mjs";
import * as Tooltip from "../Tooltip.mjs";
import * as Markdown from "../Markdown.mjs";
import * as Tooltips from "../markdown/tooltips/tooltips.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SnippetUtils from "./SnippetUtils.mjs";
import * as EventImplementationSnippet from "./EventImplementationSnippet.mjs";

function EventImplementation(Props) {
  var branchStatus = Props.branchStatus;
  var $$event = Props.event;
  var viewTypeOpt = Props.viewType;
  var model = Props.model;
  var viewType = viewTypeOpt !== undefined ? viewTypeOpt : "AllSources";
  var match = Curry._1(Mantine.Hooks.Intersection.useWithoutOptions, undefined);
  var entry = match.entry;
  var match$1 = React.useState(function (param) {
        
      });
  var setSelectedSource = match$1[1];
  var containerRef = React.useRef(null);
  SnippetUtils.useScrollIntoView($$event.id, "Event", 100, containerRef);
  var match$2 = React.useState(function (param) {
        if (entry !== undefined) {
          return entry.isIntersecting;
        } else {
          return false;
        }
      });
  var setHasScrolledIntoView = match$2[1];
  React.useEffect((function (param) {
          if (entry !== undefined && entry.isIntersecting) {
            Curry._1(setHasScrolledIntoView, (function (param) {
                    return true;
                  }));
          }
          
        }), [entry]);
  var tmp;
  if (match$2[0]) {
    var tmp$1 = {
      branchStatus: branchStatus,
      eventDiffState: "updated",
      event: $$event,
      toModel: model,
      pageName: "Event",
      viewType: viewType,
      diffViewType: "noDiff",
      onSourceChange: (function (selectedSource) {
          Curry._1(setSelectedSource, (function (param) {
                  return selectedSource;
                }));
        })
    };
    var tmp$2 = match$1[0];
    if (tmp$2 !== undefined) {
      tmp$1.source = Caml_option.valFromOption(tmp$2);
    }
    tmp = React.createElement(EventImplementationSnippet.make, tmp$1);
  } else {
    tmp = React.createElement($$Text.make, {
          size: "Medium",
          color: Styles.Color.light10,
          children: "Loading..."
        });
  }
  return React.createElement("span", {
              ref: containerRef
            }, React.createElement("section", {
                  ref: match.ref,
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.maxWidth(Css.px(700)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.paddingLeft(Css.px(28)),
                            tl: {
                              hd: Css.paddingRight(Css.px(16)),
                              tl: {
                                hd: Css.paddingTop(Css.px(2)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement(Icon.make, {
                          type_: "code",
                          color: Styles.Color.light07
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100.0)),
                            tl: {
                              hd: Css.marginRight(Css.px(41)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("header", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              children: "Tracking Code"
                            }), React.createElement(Tooltip.WithAnalytics.make, {
                              fromItem: {
                                itemId: $$event.id,
                                itemName: $$event.name,
                                itemType: "Event"
                              },
                              title: "Tracking Code",
                              children: React.createElement(Tooltip.Content.make, {
                                    children: React.createElement(Markdown.make, {
                                          source: Tooltips.trackingCode
                                        })
                                  })
                            })), React.createElement(Spacer.make, {
                          height: 16
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.maxWidth(Css.px(595)),
                                tl: /* [] */0
                              })
                        }, tmp))));
}

var make = EventImplementation;

export {
  make ,
}
/* Css Not a pure module */
