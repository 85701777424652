// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as Icons from "../Icons.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Accordion from "../Accordion.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Timestamp from "../Timestamp.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as ModelUtils from "../ModelUtils.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProfilePhoto from "../ProfilePhoto.mjs";
import * as React$1 from "motion/react";
import * as Collaborators from "../Collaborators.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as BranchStatusPill from "../BranchStatusPill.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as App from "firebase/compat/app";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";
import * as BranchOverviewRow__Extras from "./BranchOverviewRow__Extras.mjs";
import * as BranchOverviewRow__Styles from "./BranchOverviewRow__Styles.mjs";
import * as BranchStateFirebaseModelUtils from "../../../shared/models/BranchStateFirebaseModelUtils.mjs";

function BranchOverviewRow__Merged$CollapsibleItem(Props) {
  var localWorkspaceName = Props.localWorkspaceName;
  var localBranchState = Props.localBranchState;
  var workspace = Props.workspace;
  var approvalsCount = Belt_Option.getWithDefault(localBranchState.approvalsCount, 0);
  var collaboratorIds = Belt_Option.getWithDefault(localBranchState.collaboratorIds, []);
  var excessCount = collaboratorIds.length - 5 | 0;
  var match = excessCount <= 0 ? [
      collaboratorIds,
      0
    ] : [
      Belt_Array.slice(collaboratorIds, 0, 4),
      excessCount + 1 | 0
    ];
  var notDisplayedCount = match[1];
  var lastActionTimestamp = Belt_Option.mapWithDefault(localBranchState.lastActivityDate, {
        NAME: "Loaded",
        VAL: new Date()
      }, (function (ts) {
          return {
                  NAME: "Loaded",
                  VAL: ts
                };
        }));
  var lastActiveUserId = Belt_Option.mapWithDefault(localBranchState.lastActiveUserId, {
        NAME: "Loaded",
        VAL: "MV0gmhaL41d9qfb6loXwtOwYQLG3"
      }, (function (uid) {
          return {
                  NAME: "Loaded",
                  VAL: uid
                };
        }));
  var mergeAction = FirebaseFetcherHooks.useBranchMergeAction(localBranchState.schemaId, localBranchState.branchId, undefined, undefined);
  var commentsCount = localBranchState.commentsCount;
  var lastCodegenBySource = localBranchState.lastCodegenBySource;
  var tmp;
  if (localBranchState.isShallow) {
    tmp = React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {});
  } else if (lastCodegenBySource !== undefined) {
    var lastCodegenBySource$1 = Caml_option.valFromOption(lastCodegenBySource);
    tmp = Belt_MapString.size(lastCodegenBySource$1) === 0 ? React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {}) : React.createElement(BranchOverviewRow__Extras.LastCodegens.make, {
            lastCodegenBySource: lastCodegenBySource$1
          });
  } else {
    tmp = React.createElement($$Text.make, {
          element: "Em",
          size: "Small",
          color: Styles.Color.light10,
          children: "Never"
        });
  }
  var lastPublishedAt = localBranchState.lastPublishedAt;
  return React.createElement(React.Fragment, {
              children: null,
              key: localBranchState.branchId
            }, React.createElement("td", undefined, React.createElement("a", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.width("fitContent"),
                                tl: {
                                  hd: Css.gap(Css.px(8)),
                                  tl: {
                                    hd: Css.textDecoration("none"),
                                    tl: {
                                      hd: Css.color(Styles.Color.light11),
                                      tl: {
                                        hd: Css.transition({
                                              NAME: "ms",
                                              VAL: 100.0
                                            }, undefined, undefined, "color"),
                                        tl: {
                                          hd: Icon.styles({
                                                hd: Css_Legacy_Core.SVG.fill(Styles.Color.light12),
                                                tl: {
                                                  hd: Css.transition({
                                                        NAME: "ms",
                                                        VAL: 100.0
                                                      }, undefined, undefined, "all"),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Icon.color(Styles.Color.avoPink),
                                                  tl: {
                                                    hd: Css.color(Styles.Color.avoPinkShade2),
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }),
                      href: Curry._4(Router.Link.getFullPath, {
                            NAME: "schema",
                            VAL: localBranchState.schemaId
                          }, {
                            NAME: "branch",
                            VAL: localBranchState.branchId
                          }, {
                            NAME: "diff",
                            VAL: "index"
                          }, /* [] */0),
                      target: "_blank"
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          lines: 1,
                          children: localWorkspaceName
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginBottom(Css.px(-1)),
                                tl: /* [] */0
                              })
                        }, React.createElement(Icon.make, {
                              type_: "outsideLink",
                              size: "small"
                            })))), React.createElement("td", {
                  className: BranchOverviewRow__Styles.statusCellStyle
                }, React.createElement(BranchStatusPill.make, {
                      status: BranchStateFirebaseModelUtils.enrichStatusWithApprovals(localBranchState.branchStatus, approvalsCount, workspace)
                    })), React.createElement("td", {
                  className: Curry._1(Css.merge, {
                        hd: BranchOverviewRow__Styles.createdCellStyle,
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.whiteSpace("nowrap"),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light11,
                      children: mergeAction !== undefined ? React.createElement(Timestamp.make, {
                              date: Caml_option.valFromOption(mergeAction).createdAt
                            }) : React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.light09,
                              children: "-"
                            })
                    })), React.createElement("td", {
                  className: BranchOverviewRow__Styles.creatorCellStyle
                }, React.createElement(BranchOverviewRow__Extras.Creator.make, {
                      id: localBranchState.creatorUserId
                    })), React.createElement("td", undefined, React.createElement("div", {
                      className: BranchOverviewRow__Styles.collaboratorsCellStyle
                    }, Belt_Array.map(match[0], (function (id) {
                            return React.createElement("div", {
                                        key: id,
                                        className: BranchOverviewRow__Styles.collaboratorStyle
                                      }, React.createElement(ProfilePhoto.FromUserId.make, {
                                            id: id,
                                            size: 24
                                          }));
                          })), notDisplayedCount !== 0 ? React.createElement("div", {
                            className: BranchOverviewRow__Styles.collaboratorStyle
                          }, React.createElement(ProfilePhoto.Base.make, {
                                email: "+" + String(notDisplayedCount),
                                size: 24
                              })) : null)), React.createElement("td", {
                  className: Curry._1(Css.merge, {
                        hd: BranchOverviewRow__Styles.createdCellStyle,
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.whiteSpace("nowrap"),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      })
                }, typeof lastActionTimestamp === "object" && lastActionTimestamp.NAME === "Loaded" ? React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light11,
                        children: React.createElement(Timestamp.NonNull.make, {
                              date: lastActionTimestamp.VAL
                            })
                      }) : null), React.createElement("td", {
                  className: BranchOverviewRow__Styles.creatorCellStyle
                }, typeof lastActiveUserId === "object" ? React.createElement(BranchOverviewRow__Extras.Creator.make, {
                        id: lastActiveUserId.VAL
                      }) : null), React.createElement("td", undefined, React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light09,
                      children: "-"
                    })), React.createElement("td", {
                  className: BranchOverviewRow__Styles.createdCellStyle
                }, localBranchState.isShallow ? React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {}) : (
                    commentsCount !== undefined ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("row"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(Icons.$$Comment.make, {}), React.createElement(Spacer.make, {
                                width: 4
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                color: Styles.Color.light11,
                                children: String(commentsCount)
                              })) : React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {})
                  )), React.createElement("td", {
                  className: BranchOverviewRow__Styles.createdCellStyle
                }, tmp), React.createElement("td", {
                  className: BranchOverviewRow__Styles.createdCellStyle
                }, localBranchState.isShallow ? React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {}) : (
                    lastPublishedAt !== undefined ? React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light11,
                            children: React.createElement(Timestamp.make, {
                                  date: App.default.firestore.Timestamp.fromDate(Caml_option.valFromOption(lastPublishedAt))
                                })
                          }) : React.createElement($$Text.make, {
                            element: "Em",
                            size: "Small",
                            color: Styles.Color.light10,
                            children: "Never"
                          })
                  )));
}

function BranchOverviewRow__Merged$CollapsibleTr(Props) {
  var branchState = Props.branchState;
  var localWorkspaceNames = Props.localWorkspaceNames;
  var localBranchStates = Props.localBranchStates;
  var workspace = Props.workspace;
  var isPresent = React$1.useIsPresent();
  return React.createElement(React.Fragment, {
              children: Belt_Array.map(localBranchStates, (function (localBranchState) {
                      return React.createElement(React$1.motion.tr, {
                                  animate: {
                                    opacity: 1
                                  },
                                  initial: {
                                    opacity: 0
                                  },
                                  layout: true,
                                  exit: {
                                    opacity: 0
                                  },
                                  className: Curry._1(Css.style, {
                                        hd: Css.selector(" td", {
                                              hd: Css.important(Css.backgroundColor(Styles.Color.light02)),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }),
                                  key: localBranchState.branchId,
                                  onClick: (function (prim) {
                                      prim.stopPropagation();
                                    }),
                                  children: React.createElement(BranchOverviewRow__Merged$CollapsibleItem, {
                                        localWorkspaceName: Belt_MapString.getWithDefault(localWorkspaceNames, localBranchState.schemaId, "Untitled Workspace"),
                                        localBranchState: localBranchState,
                                        workspace: workspace
                                      }),
                                  style: {
                                    display: isPresent ? "table-row" : "flex",
                                    position: isPresent ? "relative" : "absolute",
                                    alignItems: isPresent ? "" : "center"
                                  }
                                });
                    })),
              key: Belt_Option.getWithDefault(branchState.globalBranchId, "noop")
            });
}

var progressBarContainerStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.backgroundColor(Styles.Color.light04),
        tl: {
          hd: Css.borderRadius(Css.px(100)),
          tl: {
            hd: Css.padding2(Css.px(2), Css.px(8)),
            tl: {
              hd: Css.marginTop(Css.px(4)),
              tl: {
                hd: Css.marginLeft(Css.px(-2)),
                tl: {
                  hd: Css.marginRight(Css.px(-2)),
                  tl: {
                    hd: Css.overflow("hidden"),
                    tl: {
                      hd: Css.height(Css.px(8)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var progressBarStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.zIndex(0),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function progressBarFillStyles(progress) {
  return Curry._1(Css.style, {
              hd: Css.width(Css.pct(progress)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.green),
                tl: {
                  hd: Css.height(Css.pct(100)),
                  tl: /* [] */0
                }
              }
            });
}

function BranchOverviewRow__Merged$MergeStatus(Props) {
  var localBranchStates = Props.localBranchStates;
  var merged = Belt_Array.keep(localBranchStates, (function (localBranchState) {
          return localBranchState.branchStatus === "Merged";
        })).length;
  var total = localBranchStates.length;
  if (total === 0) {
    return React.createElement($$Text.make, {
                size: "Small",
                color: Styles.Color.light09,
                children: "-"
              });
  }
  var pctMerged = merged * 100.0 / total;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.pct(100.0)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.gap(Css.px(4)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  singleLine: true,
                  color: Styles.Color.light12,
                  children: String(merged) + "/" + String(total) + " workspaces merged"
                }), React.createElement("div", {
                  className: progressBarContainerStyles
                }, React.createElement("div", {
                      className: progressBarStyles
                    }, React.createElement("div", {
                          className: progressBarFillStyles(pctMerged)
                        }))));
}

function BranchOverviewRow__Merged(Props) {
  var branchId = Props.branchId;
  var branchState = Props.branchState;
  var localBranchStates = Props.localBranchStates;
  var localSchemaNames = Props.localSchemaNames;
  var name = Props.name;
  var workspace = Props.workspace;
  var model = ModelStore.useModel();
  var schemaId = workspace.id;
  var match = Hooks.useDisclosure(true);
  var action = match[1];
  var collapsed = match[0];
  var schemaBundle = SchemaBundleContext.use();
  var collaboratorIds = Collaborators.useCollaboratorIds(branchId);
  var collaboratorIds$1 = branchState.isShallow ? collaboratorIds : Belt_Option.mapWithDefault(branchState.collaboratorIds, /* [] */0, Belt_List.fromArray);
  var excessCount = Belt_List.length(collaboratorIds$1) - 5 | 0;
  var match$1 = excessCount <= 0 ? [
      collaboratorIds$1,
      0
    ] : [
      Belt_Option.getWithDefault(Belt_List.take(collaboratorIds$1, 4), collaboratorIds$1),
      excessCount + 1 | 0
    ];
  var notDisplayedCount = match$1[1];
  var lastAction = FirebaseFetcherHooks.useActionLastBranchAction("SecurityAuditLog", schemaId, branchId);
  var match$2;
  if (branchState.isShallow) {
    var lastActionTimestamp;
    if (typeof lastAction === "object" && lastAction.NAME === "Loaded") {
      var lastAction$1 = lastAction.VAL;
      lastActionTimestamp = lastAction$1 !== undefined ? ({
            NAME: "Loaded",
            VAL: Caml_option.valFromOption(lastAction$1).createdAt
          }) : "Initial";
    } else {
      lastActionTimestamp = "Initial";
    }
    var lastActiveUserId;
    if (typeof lastAction === "object" && lastAction.NAME === "Loaded") {
      var lastAction$2 = lastAction.VAL;
      lastActiveUserId = lastAction$2 !== undefined ? ({
            NAME: "Loaded",
            VAL: Caml_option.valFromOption(lastAction$2).createdBy
          }) : "Initial";
    } else {
      lastActiveUserId = "Initial";
    }
    match$2 = [
      lastActionTimestamp,
      lastActiveUserId
    ];
  } else {
    var lastActivityDate = branchState.lastActivityDate;
    var lastActionTimestamp$1 = lastActivityDate !== undefined ? ({
          NAME: "Loaded",
          VAL: App.default.firestore.Timestamp.fromDate(Caml_option.valFromOption(lastActivityDate))
        }) : "Initial";
    var lastActiveUserId$1 = branchState.lastActiveUserId;
    var lastActiveUserId$2 = lastActiveUserId$1 !== undefined ? ({
          NAME: "Loaded",
          VAL: lastActiveUserId$1
        }) : "Initial";
    match$2 = [
      lastActionTimestamp$1,
      lastActiveUserId$2
    ];
  }
  var lastActiveUserId$3 = match$2[1];
  var lastActionTimestamp$2 = match$2[0];
  var sourceNames = Belt_Option.map(branchState.impactedSourceIds, (function (sourceIds) {
          return Belt_Array.mapU(sourceIds, (function (sourceId) {
                          return Belt_Option.getWithDefault(Belt_Option.flatMapU(ModelUtils.getSourceByIdWithArchive(sourceId, model), (function (source) {
                                            return source.name;
                                          })), "New Source");
                        })).join(", ");
        }));
  var sourceNamesCount = Belt_Option.flatMap(branchState.impactedSourceIds, (function (sourceIds) {
          if (sourceIds.length !== 0) {
            return sourceIds.length;
          }
          
        }));
  var handleClick = function ($$event) {
    AnalyticsRe.branchLinkClicked(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, branchId, "BranchesOverviewRow");
    var uri = Curry._4(Router.Link.get, {
          NAME: "schema",
          VAL: schemaId
        }, {
          NAME: "branch",
          VAL: branchId
        }, "metrics", /* [] */0);
    if ($$event.metaKey) {
      window.open(uri, "blank_");
      return ;
    } else {
      return Router.push(undefined, uri);
    }
  };
  var tmp = {
    size: "small",
    isOpen: !collapsed
  };
  var tmp$1 = localBranchStates.length === 0 ? Styles.Color.light04 : undefined;
  if (tmp$1 !== undefined) {
    tmp.color = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    className: BranchOverviewRow__Styles.createdCellStyle
  };
  if (sourceNames !== undefined) {
    tmp$2.title = Caml_option.valFromOption(sourceNames);
  }
  var commentsCount = branchState.commentsCount;
  var lastCodegenBySource = branchState.lastCodegenBySource;
  var tmp$3;
  if (branchState.isShallow) {
    tmp$3 = React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {});
  } else if (lastCodegenBySource !== undefined) {
    var lastCodegenBySource$1 = Caml_option.valFromOption(lastCodegenBySource);
    tmp$3 = Belt_MapString.size(lastCodegenBySource$1) === -1 ? React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {}) : React.createElement(BranchOverviewRow__Extras.LastCodegens.make, {
            lastCodegenBySource: lastCodegenBySource$1
          });
  } else {
    tmp$3 = React.createElement($$Text.make, {
          element: "Em",
          size: "Small",
          color: Styles.Color.light09,
          children: "Never"
        });
  }
  var lastPublishedAt = branchState.lastPublishedAt;
  return React.createElement(React.Fragment, undefined, React.createElement("tr", {
                  onClick: (function (param) {
                      if (localBranchStates.length !== 0) {
                        return Curry._1(action.toggle, undefined);
                      }
                      
                    })
                }, React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.gap(Css.px(2)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement(Accordion.OpeningIcon.make, tmp), React.createElement("button", {
                          className: Curry._1(Css.style, Styles.buttonReset),
                          onClick: handleClick
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              lines: 1,
                              color: Styles.Color.light10,
                              children: name
                            }))), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100.0)),
                            tl: /* [] */0
                          })
                    }, React.createElement(BranchOverviewRow__Merged$MergeStatus, {
                          localBranchStates: localBranchStates
                        })), React.createElement("td", {
                      className: Curry._1(Css.merge, {
                            hd: BranchOverviewRow__Styles.createdCellStyle,
                            tl: {
                              hd: Curry._1(Css.style, {
                                    hd: Css.whiteSpace("nowrap"),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.light10,
                          children: React.createElement(Timestamp.NonNull.make, {
                                date: branchState.creationDate
                              })
                        })), React.createElement("td", {
                      className: BranchOverviewRow__Styles.creatorCellStyle
                    }, React.createElement(BranchOverviewRow__Extras.Creator.make, {
                          id: branchState.creatorUserId
                        })), React.createElement("td", undefined, React.createElement("div", {
                          className: BranchOverviewRow__Styles.collaboratorsCellStyle
                        }, Belt_List.toArray(Belt_List.map(match$1[0], (function (id) {
                                    return React.createElement("div", {
                                                key: id,
                                                className: BranchOverviewRow__Styles.collaboratorStyle
                                              }, React.createElement(ProfilePhoto.FromUserId.make, {
                                                    id: id,
                                                    size: 23
                                                  }));
                                  }))), notDisplayedCount !== -1 ? React.createElement("div", {
                                className: BranchOverviewRow__Styles.collaboratorStyle
                              }, React.createElement(ProfilePhoto.Base.make, {
                                    email: "+" + String(notDisplayedCount),
                                    size: 23
                                  })) : null)), React.createElement("td", {
                      className: Curry._1(Css.merge, {
                            hd: BranchOverviewRow__Styles.createdCellStyle,
                            tl: {
                              hd: Curry._1(Css.style, {
                                    hd: Css.whiteSpace("nowrap"),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          })
                    }, typeof lastActionTimestamp$2 === "object" && lastActionTimestamp$2.NAME === "Loaded" ? React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light10,
                            children: React.createElement(Timestamp.make, {
                                  date: lastActionTimestamp$2.VAL
                                })
                          }) : null), React.createElement("td", {
                      className: BranchOverviewRow__Styles.creatorCellStyle
                    }, typeof lastActiveUserId$3 === "object" ? React.createElement(BranchOverviewRow__Extras.Creator.make, {
                            id: lastActiveUserId$3.VAL
                          }) : null), React.createElement("td", tmp$2, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.flexDirection("row"),
                                  tl: {
                                    hd: Css.flexWrap("wrap"),
                                    tl: {
                                      hd: Css.gap(Css.px(1)),
                                      tl: {
                                        hd: Css.maxHeight(Css.pct(99.0)),
                                        tl: {
                                          hd: Css.overflow("hidden"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              })
                        }, branchState.isShallow ? React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {}) : (
                            sourceNames !== undefined ? React.createElement($$Text.make, {
                                    size: "Small",
                                    lines: 1,
                                    color: Styles.Color.light11,
                                    children: null
                                  }, Belt_Option.mapWithDefault(sourceNamesCount, React.createElement($$Text.make, {
                                            element: "Em",
                                            size: "Small",
                                            color: Styles.Color.light09,
                                            children: "None"
                                          }), (function (sourceNamesCount) {
                                          return React.createElement($$Text.make, {
                                                      element: "Span",
                                                      weight: "Semi",
                                                      children: String(sourceNamesCount) + ": "
                                                    });
                                        })), sourceNames) : null
                          ))), React.createElement("td", {
                      className: BranchOverviewRow__Styles.createdCellStyle
                    }, branchState.isShallow ? React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {}) : (
                        commentsCount !== undefined ? React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.flexDirection("row"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement(Icons.$$Comment.make, {}), React.createElement(Spacer.make, {
                                    width: 3
                                  }), React.createElement($$Text.make, {
                                    size: "Small",
                                    color: Styles.Color.light10,
                                    children: String(commentsCount)
                                  })) : React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {})
                      )), React.createElement("td", {
                      className: BranchOverviewRow__Styles.createdCellStyle
                    }, tmp$3), React.createElement("td", {
                      className: BranchOverviewRow__Styles.createdCellStyle
                    }, branchState.isShallow ? React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {}) : (
                        lastPublishedAt !== undefined ? React.createElement($$Text.make, {
                                size: "Small",
                                color: Styles.Color.light10,
                                children: React.createElement(Timestamp.make, {
                                      date: App.default.firestore.Timestamp.fromDate(Caml_option.valFromOption(lastPublishedAt))
                                    })
                              }) : React.createElement($$Text.make, {
                                element: "Em",
                                size: "Small",
                                color: Styles.Color.light09,
                                children: "Never"
                              })
                      ))), collapsed ? null : React.createElement(BranchOverviewRow__Merged$CollapsibleTr, {
                    branchState: branchState,
                    localWorkspaceNames: localSchemaNames,
                    localBranchStates: localBranchStates,
                    workspace: workspace,
                    key: Belt_Option.getWithDefault(branchState.globalBranchId, "noop")
                  }));
}

var make = BranchOverviewRow__Merged;

export {
  make ,
}
/* progressBarContainerStyles Not a pure module */
