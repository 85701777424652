// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Styles from "../styles.mjs";
import * as $$Comment from "../Comment.mjs";
import * as AvoUtils from "../../../shared/utils/AvoUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as ModelUtils from "../ModelUtils.mjs";
import * as UsersStore from "../UsersStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as IconArrowUp from "../IconArrowUp.mjs";
import * as IconArrowDown from "../IconArrowDown.mjs";
import * as CollapsedContent from "../CollapsedContent.mjs";
import * as TrackingPlanModel from "../../../model/src/TrackingPlanModel.mjs";
import * as ObjectActivityLogAction from "../ObjectActivityLogAction.mjs";

function IntegrationActivityItem$DocsLinkOnError(Props) {
  var type_ = Props.type_;
  return React.createElement("a", {
              className: Curry._1(Css.style, {
                    hd: Css.textDecoration("none"),
                    tl: {
                      hd: Css.marginTop(Css.px(4)),
                      tl: {
                        hd: Css.hover({
                              hd: Css.textDecoration("underline"),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }),
              href: "https://www.avo.app/docs/publishing/publishing/overview",
              rel: "noopener",
              target: "_blank"
            }, React.createElement($$Text.make, {
                  element: "Span",
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.primaryPurpleShade1,
                  children: "Learn more about publishing " + (
                    type_ === "Errors" ? "errors" : "warnings"
                  ) + " in the docs"
                }));
}

var DocsLinkOnError = {
  make: IntegrationActivityItem$DocsLinkOnError
};

function containerStyle(publishingMessagesType) {
  return {
          hd: Css.border(Css.px(1), "solid", publishingMessagesType === "Errors" ? Styles.Color.darkRed : Styles.Color.orange),
          tl: {
            hd: Css.borderRadius(Styles.Border.radius),
            tl: {
              hd: Css.marginBottom(Css.px(8)),
              tl: {
                hd: Css.margin4(Css.px(4), Css.px(45), Css.px(12), Css.px(45)),
                tl: /* [] */0
              }
            }
          }
        };
}

function toggleButtonStyles(collapsed, publishingMessagesType) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.button),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.medium),
                            tl: {
                              hd: Css.lineHeight(Styles.LineHeight.regular),
                              tl: {
                                hd: Css.color(publishingMessagesType === "Errors" ? Styles.Color.darkRed : Styles.Color.darkOrange),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.small),
                                  tl: {
                                    hd: Css.padding2(Css.px(8), Css.px(12)),
                                    tl: {
                                      hd: Css.width(Css.pct(100)),
                                      tl: {
                                        hd: Css.textAlign("left"),
                                        tl: {
                                          hd: Css.transition({
                                                NAME: "ms",
                                                VAL: Styles.Duration.$$short
                                              }, undefined, undefined, "background-color"),
                                          tl: {
                                            hd: Css.borderBottom(Css.px(collapsed ? 0 : 1), "solid", publishingMessagesType === "Errors" ? Styles.Color.red : Styles.Color.orange),
                                            tl: {
                                              hd: Css.backgroundColor(publishingMessagesType === "Errors" ? Styles.Color.lightRed : Styles.Color.lightOrange),
                                              tl: {
                                                hd: Css.borderRadius(Css.px(4)),
                                                tl: {
                                                  hd: Css.borderBottomLeftRadius(Css.px(collapsed ? 4 : 0)),
                                                  tl: {
                                                    hd: Css.borderBottomRightRadius(Css.px(collapsed ? 4 : 0)),
                                                    tl: {
                                                      hd: Css.hover({
                                                            hd: Css.backgroundColor(publishingMessagesType === "Errors" ? Css.hex("FFCECC") : Css.hex("F9E69F")),
                                                            tl: /* [] */0
                                                          }),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var toggleButtonLabelStyles = Curry._1(Css.style, {
      hd: Css.marginRight(Css.px(4)),
      tl: /* [] */0
    });

function IntegrationActivityItem$PublishingMessages(Props) {
  var messages = Props.messages;
  var publishingMessagesType = Props.publishingMessagesType;
  return React.createElement(CollapsedContent.make, {
              button: (function (onClick, collapsed) {
                  var tmp;
                  tmp = publishingMessagesType === "Errors" ? (
                      collapsed ? "View errors" : "Hide errors"
                    ) : (
                      collapsed ? "View warnings" : "Hide warnings"
                    );
                  return React.createElement("button", {
                              className: toggleButtonStyles(collapsed, publishingMessagesType),
                              onClick: (function (param) {
                                  Curry._1(onClick, undefined);
                                })
                            }, React.createElement("div", {
                                  className: toggleButtonLabelStyles
                                }, React.createElement($$Text.make, {
                                      weight: "Semi",
                                      children: tmp
                                    })), collapsed ? React.createElement(IconArrowDown.make, {
                                    size: 8,
                                    color: Styles.Color.light11
                                  }) : React.createElement(IconArrowUp.make, {
                                    size: 8,
                                    color: Styles.Color.light11
                                  }));
                }),
              containerStyle: containerStyle(publishingMessagesType),
              children: (function (param) {
                  return React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding2(Css.px(8), Css.px(12)),
                                    tl: /* [] */0
                                  })
                            }, Belt_Array.map(messages, (function (message) {
                                    return React.createElement($$Text.make, {
                                                size: "Small",
                                                weight: "Regular",
                                                color: publishingMessagesType === "Errors" ? Styles.Color.red : Styles.Color.light12,
                                                children: message,
                                                key: message
                                              });
                                  })), React.createElement(IntegrationActivityItem$DocsLinkOnError, {
                                  type_: publishingMessagesType
                                }));
                })
            });
}

var PublishingMessages = {
  containerStyle: containerStyle,
  toggleButtonStyles: toggleButtonStyles,
  toggleButtonLabelStyles: toggleButtonLabelStyles,
  make: IntegrationActivityItem$PublishingMessages
};

function printSuccessMessage(param, integrationConfig, success, auto) {
  var numSuccessfulCategories = param.numSuccessfulCategories;
  var numSuccessfulPropertyBundles = param.numSuccessfulPropertyBundles;
  var numSuccessfulProperties = param.numSuccessfulProperties;
  var numSuccessfulEvents = param.numSuccessfulEvents;
  var version = param.version;
  if (version !== undefined) {
    return (
            auto ? "auto-" : ""
          ) + "published version " + version + (
            success ? " successfully" : " with errors"
          );
  }
  var parts = [];
  var prefix = (
    auto ? "auto-" : ""
  ) + "published " + (
    success ? " successfully" : " with errors"
  );
  if (numSuccessfulEvents > 0) {
    var eventName = integrationConfig !== undefined && integrationConfig.TAG === "AdobeExperiencePlatform" ? "schema" : "event";
    parts.push(AvoUtils.plural(undefined, undefined, numSuccessfulEvents, eventName));
  }
  if (numSuccessfulProperties > 0) {
    parts.push(AvoUtils.plural("properties", undefined, numSuccessfulProperties, "property"));
  }
  if (numSuccessfulCategories > 0) {
    parts.push(AvoUtils.plural("categories", undefined, numSuccessfulCategories, "category"));
  }
  if (numSuccessfulPropertyBundles > 0) {
    var propertyBundleName = integrationConfig !== undefined && integrationConfig.TAG === "AdobeExperiencePlatform" ? "field group" : "property bundle";
    parts.push(AvoUtils.plural(undefined, undefined, numSuccessfulPropertyBundles, propertyBundleName));
  }
  var len = parts.length;
  var tmp;
  if (len !== 1) {
    tmp = len !== 0 ? " and updated " + parts.slice(0, parts.length - 1 | 0).join(", ") + (" and " + Belt_Option.getExn(Belt_Array.get(parts, parts.length - 1 | 0))) : "";
  } else {
    var single = parts[0];
    tmp = " and updated " + single;
  }
  return prefix + tmp;
}

function IntegrationActivityItem(Props) {
  var events = Props.events;
  var action = Props.action;
  var userId = Props.userId;
  var userIds = Props.userIds;
  var goToId = Props.goToId;
  var openBranches = Props.openBranches;
  var integrationName = Props.integrationName;
  var showComments = Props.showComments;
  var users = UsersStore.use(userIds);
  var model = ModelStore.useModel();
  if (typeof users !== "object") {
    return null;
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var match = action.contents;
  if (typeof match !== "object") {
    return null;
  }
  var variant = match.NAME;
  if (variant === "CompletedImport" || variant === "ImportDeprecated") {
    return null;
  }
  if (variant === "UpdateIntegrationAutoPublish") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: (
                  match.VAL[1] ? "enabled" : "disabled"
                ) + " auto publishing"
              });
  }
  if (variant === "UpdateIntegrationConfig") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "changed the integration config"
              });
  }
  if (variant === "RemoveIntegration") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "deleted the integration"
              });
  }
  if (variant === "IntegrationComment") {
    if (!showComments) {
      return null;
    }
    var match$1 = match.VAL;
    var commentId = match$1[1];
    var integrationId = match$1[0];
    return React.createElement($$Comment.make, {
                threadId: integrationId,
                itemType: "Integration",
                itemName: integrationName,
                commentId: commentId,
                events: events,
                userId: userId,
                goToId: goToId,
                action: action,
                link: (function (param) {
                    return Router.Link.addDrawerItem(undefined, {
                                NAME: "integration",
                                VAL: [
                                  integrationId,
                                  commentId
                                ]
                              });
                  }),
                openBranches: openBranches
              });
  }
  if (variant === "GenerateWebhookSecret") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "Generated Webhook Secret"
              });
  }
  if (variant === "CreateIntegration") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "created the integration"
              });
  }
  if (variant === "PublishIntegration") {
    var match$2 = match.VAL;
    var integrationId$1 = match$2[0];
    if (match$2[1]) {
      var errors = match$2[3];
      var warnings = match$2[2];
      var integrationConfig = Belt_Option.flatMap(ModelUtils.getIntegrationById(integrationId$1, model), (function (integration) {
              return integration.config;
            }));
      var tmp;
      var exit = 0;
      if (warnings.length !== 0 || errors.length !== 0) {
        exit = 1;
      } else {
        tmp = null;
      }
      if (exit === 1) {
        tmp = React.createElement(IntegrationActivityItem$PublishingMessages, {
              messages: Belt_Array.concat(warnings, errors),
              publishingMessagesType: "Warnings"
            });
      }
      return React.createElement(React.Fragment, undefined, React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: Belt_Option.mapWithDefault(match$2[4], "successfully published the integration", (function (successStats) {
                              return printSuccessMessage(successStats, integrationConfig, true, false);
                            }))
                    }), tmp);
    }
    var errors$1 = match$2[3];
    var warnings$1 = match$2[2];
    var integrationConfig$1 = Belt_Option.flatMap(ModelUtils.getIntegrationById(integrationId$1, model), (function (integration) {
            return integration.config;
          }));
    return React.createElement(React.Fragment, undefined, React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: Belt_Option.mapWithDefault(match$2[4], "published the integration with errors", (function (successStats) {
                            return printSuccessMessage(successStats, integrationConfig$1, false, false);
                          }))
                  }), warnings$1.length !== 0 ? React.createElement(IntegrationActivityItem$PublishingMessages, {
                      messages: warnings$1,
                      publishingMessagesType: "Warnings"
                    }) : null, errors$1.length !== 0 ? React.createElement(IntegrationActivityItem$PublishingMessages, {
                      messages: errors$1,
                      publishingMessagesType: "Errors"
                    }) : null);
  }
  if (variant === "UpdateIntegrationName") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: null
              }, "changed the integration name to ", React.createElement("b", undefined, match.VAL[1]));
  }
  if (variant === "UpdateIntegrationType") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: null
              }, "changed the integration type to ", React.createElement("b", undefined, TrackingPlanModel.integrationTypeHumanReadableName(match.VAL[1])));
  }
  if (variant === "UpdateIntegrationFilters") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "changed the events filter"
              });
  }
  if (variant !== "AutoPublishIntegration") {
    return null;
  }
  var match$3 = match.VAL;
  var integrationId$2 = match$3[0];
  if (match$3[1]) {
    var errors$2 = match$3[3];
    var warnings$2 = match$3[2];
    var integrationConfig$2 = Belt_Option.flatMap(ModelUtils.getIntegrationById(integrationId$2, model), (function (integration) {
            return integration.config;
          }));
    var tmp$1;
    var exit$1 = 0;
    if (warnings$2.length !== 0 || errors$2.length !== 0) {
      exit$1 = 1;
    } else {
      tmp$1 = null;
    }
    if (exit$1 === 1) {
      tmp$1 = React.createElement(IntegrationActivityItem$PublishingMessages, {
            messages: Belt_Array.concat(warnings$2, errors$2),
            publishingMessagesType: "Warnings"
          });
    }
    return React.createElement(React.Fragment, undefined, React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: [],
                    openBranches: openBranches,
                    children: Belt_Option.mapWithDefault(match$3[4], "Auto Publish succeeded", (function (successStats) {
                            return printSuccessMessage(successStats, integrationConfig$2, true, true);
                          }))
                  }), tmp$1);
  }
  var errors$3 = match$3[3];
  var warnings$3 = match$3[2];
  var integrationConfig$3 = Belt_Option.flatMap(ModelUtils.getIntegrationById(integrationId$2, model), (function (integration) {
          return integration.config;
        }));
  return React.createElement(React.Fragment, undefined, React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: [],
                  openBranches: openBranches,
                  children: Belt_Option.mapWithDefault(match$3[4], "Auto Publish failed", (function (successStats) {
                          return printSuccessMessage(successStats, integrationConfig$3, false, true);
                        }))
                }), warnings$3.length !== 0 ? React.createElement(IntegrationActivityItem$PublishingMessages, {
                    messages: warnings$3,
                    publishingMessagesType: "Warnings"
                  }) : null, errors$3.length !== 0 ? React.createElement(IntegrationActivityItem$PublishingMessages, {
                    messages: errors$3,
                    publishingMessagesType: "Errors"
                  }) : null);
}

var make = IntegrationActivityItem;

export {
  DocsLinkOnError ,
  PublishingMessages ,
  printSuccessMessage ,
  make ,
}
/* toggleButtonLabelStyles Not a pure module */
