// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Router from "./Router.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Changes from "../../shared/Changes.mjs";
import * as Printer from "../../model/src/Printer.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as DiffView from "./DiffView.mjs";
import * as Redirect from "./Redirect.mjs";
import * as DiffUtils from "./DiffUtils.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RouterStore from "./RouterStore.mjs";
import * as BranchHeader from "./BranchHeader.mjs";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as BranchStatusModel from "../../shared/models/BranchStatusModel.mjs";
import * as LoadingFullscreen from "./LoadingFullscreen.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as BranchImplementation from "./branchImplementation/BranchImplementation.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as ChangesWorkerInstance from "./workers/ChangesWorkerInstance.mjs";
import * as FlattenVariantsUseCase from "../../model/src/variants/flattenVariantsUseCase.mjs";
import * as BranchImplementationHooks from "./branchImplementation/BranchImplementationHooks.mjs";
import * as BranchActionDiffCalculator from "./BranchActionDiffCalculator.mjs";

function BranchView$BranchViewTab(Props) {
  var actions = Props.actions;
  var branchStatus = Props.branchStatus;
  var fromModel = Props.fromModel;
  var fromModelWithFlattenedEventVariants = Props.fromModelWithFlattenedEventVariants;
  var toModel = Props.toModel;
  var branchName = Props.branchName;
  var mergeWarnings = Props.mergeWarnings;
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchId = Props.branchId;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var masterSinceOpenedDiff = Props.masterSinceOpenedDiff;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var mergeConflicts = Props.mergeConflicts;
  var branchOpenAction = Props.branchOpenAction;
  var role = Props.role;
  var actionDiff = Props.actionDiff;
  var openBranches = Props.openBranches;
  var sourceUpdates = Props.sourceUpdates;
  var changes = Props.changes;
  var branchDiffStatus = Props.branchDiffStatus;
  var schemaRoute = RouterStore.Schema.useSchemaRoute();
  var exit = 0;
  if (typeof schemaRoute === "object" && schemaRoute.NAME === "diff") {
    var match = schemaRoute.VAL;
    if (typeof match === "object") {
      if (match.NAME !== "comment") {
        return React.createElement(BranchImplementation.make, {
                    branchStatus: branchStatus,
                    fromModel: fromModel,
                    fromModelWithFlattenedEventVariants: fromModelWithFlattenedEventVariants,
                    subRoute: match.VAL,
                    toModel: toModel,
                    sourceUpdates: sourceUpdates
                  });
      }
      exit = 2;
    } else {
      exit = 2;
    }
  } else {
    exit = 1;
  }
  switch (exit) {
    case 1 :
        return React.createElement(Redirect.make, {
                    path: Router.Link.getSchemaRouteLink(undefined, "dashboard")
                  });
    case 2 :
        return React.createElement(DiffView.make, {
                    branchStatus: branchStatus,
                    branchName: branchName,
                    mergeWarnings: mergeWarnings,
                    masterModel: masterModel,
                    branchModel: branchModel,
                    branchModelWithPulledMaster: branchModelWithPulledMaster,
                    branchId: branchId,
                    masterModelBeforeBranch: masterModelBeforeBranch,
                    masterSinceOpenedDiff: masterSinceOpenedDiff,
                    latestMasterAction: masterModelLatestAction,
                    actions: actions,
                    mergeConflicts: mergeConflicts,
                    branchOpenAction: branchOpenAction,
                    role: role,
                    actionDiff: actionDiff,
                    openBranches: openBranches,
                    sourceUpdates: sourceUpdates,
                    changes: changes,
                    branchDiffStatus: branchDiffStatus
                  });
    
  }
}

var BranchViewTab = {
  make: BranchView$BranchViewTab
};

function BranchView$WithCurrentMasterOrMasterAtBranchMerge(Props) {
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchOpenAction = Props.branchOpenAction;
  var branchStatus = Props.branchStatus;
  var branchModelLatestAction = Props.branchModelLatestAction;
  var diffInformation = Props.diffInformation;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var masterModel = Props.masterModel;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use();
  var match = React.useReducer((function (state, action) {
          if (typeof action === "object") {
            return {
                    changes: action.VAL,
                    status: "loaded"
                  };
          } else {
            return {
                    changes: state.changes,
                    status: "loading"
                  };
          }
        }), {
        changes: Changes.empty,
        status: "loading"
      });
  var reduceChangeState = match[1];
  var changeState = match[0];
  var match$1 = React.useState(function (param) {
        return "loading";
      });
  var setBranchDiffStatus = match$1[1];
  var branchDiffStatus = match$1[0];
  var match$2 = React.useState(function (param) {
        return {
                calculationId: "",
                fromModel: TrackingPlanModel.empty,
                toModel: TrackingPlanModel.empty,
                rawBranchChanges: /* [] */0,
                rebasedBranchChanges: /* [] */0
              };
      });
  var setBranchDiff = match$2[1];
  var match$3 = match$2[0];
  var rebasedBranchChanges = match$3.rebasedBranchChanges;
  var rawBranchChanges = match$3.rawBranchChanges;
  var toModel = match$3.toModel;
  var fromModel = match$3.fromModel;
  var getCalculationId = function (param) {
    return Belt_Option.getWithDefault(Belt_Option.map(branchModelLatestAction, (function (branchModelLatestAction) {
                      return Models.Action.getId(branchModelLatestAction);
                    })), new Date().toISOString());
  };
  var latestCalculationId = React.useRef(getCalculationId());
  var diffCalculatorAborter = React.useRef(undefined);
  React.useEffect((function (param) {
          Curry._1(setBranchDiffStatus, (function (status) {
                  if (status === "loading") {
                    return "loading";
                  } else {
                    return "updating";
                  }
                }));
          var calculationId = getCalculationId();
          latestCalculationId.current = calculationId;
          var controller = new AbortController();
          diffCalculatorAborter.current = Caml_option.some(controller);
          var signal = controller.signal;
          var json = diffInformation.fromAndToModels.from.json;
          var json$1 = diffInformation.fromAndToModels.to.json;
          BranchActionDiffCalculator.calculateDiffInWorker(diffInformation.fromAndToModels.from.model, json !== undefined ? json : Printer.printModel(diffInformation.fromAndToModels.from.model), diffInformation.fromAndToModels.to.model, json$1 !== undefined ? json$1 : Printer.printModel(diffInformation.fromAndToModels.to.model), diffInformation.mainModelBeforeBranch, branchModel, calculationId, signal).then(function (diffCalculatorResult) {
                if (typeof diffCalculatorResult !== "object") {
                  return ;
                }
                var diffCalculatorResult$1 = diffCalculatorResult.VAL;
                if (diffCalculatorResult$1.calculationId === latestCalculationId.current && !signal.aborted) {
                  Curry._1(setBranchDiff, (function (param) {
                          return diffCalculatorResult$1;
                        }));
                  return Curry._1(setBranchDiffStatus, (function (param) {
                                return "loaded";
                              }));
                }
                
              });
          return (function (param) {
                    Belt_Option.forEach(diffCalculatorAborter.current, (function (prim) {
                            prim.abort();
                          }));
                  });
        }), [
        diffInformation,
        branchModel
      ]);
  var actions = React.useMemo((function (param) {
          return AvoUtils.groupActionsByObjectId(AvoUtils.actionByObject(rebasedBranchChanges));
        }), [rebasedBranchChanges]);
  var rawBranchChangesWithItems = React.useMemo((function (param) {
          return AvoUtils.actionByObject(rawBranchChanges);
        }), [rawBranchChanges]);
  var groupedRawBranchChangesWithItemn = React.useMemo((function (param) {
          return AvoUtils.groupActionsByObjectId(rawBranchChangesWithItems);
        }), [rawBranchChangesWithItems]);
  var mergeWarnings = React.useMemo((function (param) {
          return DiffUtils.getMergeWarnings(groupedRawBranchChangesWithItemn, masterModel);
        }), [
        groupedRawBranchChangesWithItemn,
        masterModel
      ]);
  var mergeConflicts = React.useMemo((function (param) {
          return DiffUtils.getMergeConflicts(rawBranchChangesWithItems, branchModel, masterModel);
        }), [
        rawBranchChangesWithItems,
        branchModel,
        masterModel
      ]);
  var match$4 = branchOpenAction.contents;
  var branchName = typeof match$4 === "object" && match$4.NAME === "OpenBranch" ? match$4.VAL[1] : "N/A";
  var fromModelWithFlattenedEventVariants = React.useMemo((function (param) {
          return FlattenVariantsUseCase.flatten(fromModel);
        }), [fromModel]);
  var toModelWithFlattenedEventVariants = React.useMemo((function (param) {
          return FlattenVariantsUseCase.flatten(toModel);
        }), [toModel]);
  var fromModelWithFlattenedEventVariantsJson = React.useMemo((function (param) {
          return Printer.printModel(fromModelWithFlattenedEventVariants);
        }), [fromModelWithFlattenedEventVariants]);
  var toModelWithFlattenedEventVariantsJson = React.useMemo((function (param) {
          return Printer.printModel(toModelWithFlattenedEventVariants);
        }), [toModelWithFlattenedEventVariants]);
  React.useEffect((function (param) {
          Curry._1(reduceChangeState, "loading");
          $$Promise.$$catch(ChangesWorkerInstance.Stable.getModelChanges(fromModelWithFlattenedEventVariantsJson, toModelWithFlattenedEventVariantsJson).then(function (changes) {
                    Curry._1(reduceChangeState, {
                          NAME: "loaded",
                          VAL: changes
                        });
                  }), (function (e) {
                  Sentry.captureException(e, {
                        message: "Failed to resolve changes from Worker",
                        schemaId: schemaBundle.schemaId,
                        branchId: schemaBundle.branchId
                      });
                  Curry._1(reduceChangeState, {
                        NAME: "loaded",
                        VAL: Changes.getChanges(fromModelWithFlattenedEventVariants, toModelWithFlattenedEventVariants)
                      });
                  return Promise.resolve();
                }));
        }), [
        fromModelWithFlattenedEventVariants,
        toModelWithFlattenedEventVariants
      ]);
  var sourceUpdates = BranchImplementationHooks.useSourceUpdates(toModelWithFlattenedEventVariants.sources, false, changeState.changes);
  if (branchDiffStatus === "loading") {
    return React.createElement(LoadingFullscreen.make, {
                message: "Loading tracking plan changes..."
              });
  } else {
    return React.createElement(React.Fragment, undefined, React.createElement(BranchHeader.make, {
                    changeStatus: changeState.status,
                    sourceUpdates: sourceUpdates,
                    branchDiffStatus: branchDiffStatus
                  }), React.createElement(BranchView$BranchViewTab, {
                    actions: actions,
                    branchStatus: branchStatus,
                    fromModel: fromModel,
                    fromModelWithFlattenedEventVariants: fromModelWithFlattenedEventVariants,
                    toModel: toModel,
                    branchName: branchName,
                    mergeWarnings: mergeWarnings,
                    masterModel: masterModel,
                    branchModel: branchModel,
                    branchModelWithPulledMaster: diffInformation.rebasedBranchModel,
                    branchId: branchId,
                    masterModelBeforeBranch: diffInformation.mainModelBeforeBranch,
                    masterSinceOpenedDiff: diffInformation.mainBranchChanges,
                    masterModelLatestAction: masterModelLatestAction,
                    mergeConflicts: mergeConflicts,
                    branchOpenAction: branchOpenAction,
                    role: role,
                    actionDiff: rebasedBranchChanges,
                    openBranches: openBranches,
                    sourceUpdates: sourceUpdates,
                    changes: changeState.changes,
                    branchDiffStatus: branchDiffStatus
                  }));
  }
}

var WithCurrentMasterOrMasterAtBranchMerge = {
  make: BranchView$WithCurrentMasterOrMasterAtBranchMerge
};

function BranchView$WithMasterModel(Props) {
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchOpenAction = Props.branchOpenAction;
  var branchModelLatestAction = Props.branchModelLatestAction;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var masterModel = Props.masterModel;
  var maybeCloseAction = Props.maybeCloseAction;
  var maybeMergeAction = Props.maybeMergeAction;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var setStateActions = Props.setStateActions;
  var branchStatus = BranchStatusModel.get(branchOpenAction, maybeMergeAction, maybeCloseAction, setStateActions);
  var maybeDiffInformation = ModelStore.useDiffInformation();
  if (maybeDiffInformation !== undefined) {
    return React.createElement(BranchView$WithCurrentMasterOrMasterAtBranchMerge, {
                branchId: branchId,
                branchModel: branchModel,
                branchOpenAction: branchOpenAction,
                branchStatus: branchStatus,
                branchModelLatestAction: branchModelLatestAction,
                diffInformation: maybeDiffInformation,
                masterModelLatestAction: masterModelLatestAction,
                masterModel: masterModel,
                openBranches: openBranches,
                role: role
              });
  } else {
    return React.createElement(LoadingFullscreen.make, {
                message: "Loading tracking plan changes..."
              });
  }
}

var WithMasterModel = {
  make: BranchView$WithMasterModel
};

function BranchView(Props) {
  var masterModel = Props.masterModel;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var branchModelLatestAction = Props.branchModelLatestAction;
  var branchModel = Props.branchModel;
  var branchId = Props.branchId;
  var role = Props.role;
  var openBranches = Props.openBranches;
  var workspace = WorkspaceContext.use();
  var branchLifecycleState = FirebaseFetcherHooks.useBranchLifecycleActions(workspace.id, branchId, undefined, undefined);
  var branchOpenAction = branchLifecycleState[0];
  if (branchOpenAction === undefined) {
    return React.createElement(LoadingCircle.make, {});
  }
  var branchOpenAction$1 = Caml_option.valFromOption(branchOpenAction);
  var match = branchModel.branchPointer;
  return React.createElement(BranchView$WithMasterModel, {
              branchId: branchId,
              branchModel: branchModel,
              branchOpenAction: branchOpenAction$1,
              branchModelLatestAction: branchModelLatestAction,
              masterModelLatestAction: masterModelLatestAction,
              masterModel: masterModel,
              maybeCloseAction: branchLifecycleState[2],
              maybeMergeAction: branchLifecycleState[1],
              openBranches: openBranches,
              role: role,
              setStateActions: branchLifecycleState[3],
              key: "masterModelRefetchKey-" + (
                match !== undefined ? match[1] : Belt_Option.getWithDefault(Belt_Option.map(Caml_option.nullable_to_opt(Models.Action.getCreatedAt(branchOpenAction$1)), (function (date) {
                              return date.toDate().toString();
                            })), "")
              )
            });
}

var make = BranchView;

export {
  BranchViewTab ,
  WithCurrentMasterOrMasterAtBranchMerge ,
  WithMasterModel ,
  make ,
}
/* react Not a pure module */
