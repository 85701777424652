// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as JsDiff from "./externals/JsDiff.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Collapsible from "./Collapsible.mjs";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as DiffComponents from "./DiffComponents.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as DiffCollapseButton from "./DiffCollapseButton.mjs";
import * as CopyChangesToBranchModal__Hooks from "./CopyChangesToBranchModal__Hooks.mjs";

function renderRow(label, value) {
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("spaceBetween"),
                        tl: {
                          hd: Css.padding2(Css.px(15), Css.px(20)),
                          tl: {
                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flex({
                              NAME: "num",
                              VAL: 1.0
                            }),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: /* [] */0
                          }
                        }
                      })
                }, label), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flex({
                              NAME: "num",
                              VAL: 2.0
                            }),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.justifyContent("flexEnd"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, value));
}

function DiffSource$Source(Props) {
  var actions = Props.actions;
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var branchStatus = Props.branchStatus;
  var source = Props.source;
  var nextSource = Props.nextSource;
  var branchId = Props.branchId;
  var withCopyToBranchOpt = Props.withCopyToBranch;
  var collapsibleOpt = Props.collapsible;
  var withCopyToBranch = withCopyToBranchOpt !== undefined ? withCopyToBranchOpt : false;
  var collapsible = collapsibleOpt !== undefined ? collapsibleOpt : false;
  var globalSend = GlobalSendContext.use();
  var workspace = WorkspaceContext.use();
  var actions$1 = Belt_List.map(actions, (function (prim) {
          return prim[1];
        }));
  if (!actions$1) {
    return null;
  }
  var hasCreate = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "CreateSource";
          } else {
            return false;
          }
        }));
  var hasArchive = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          if (x.NAME !== "Archive") {
            return false;
          }
          var match = x.VAL;
          if (typeof match === "object") {
            return match.NAME === "Source";
          } else {
            return false;
          }
        }));
  var changeType = hasCreate !== undefined ? "Create" : (
      hasArchive !== undefined ? "Archive" : "Update"
    );
  var addedOrModifiedDestinations = Belt_List.toArray(Belt_List.map(Belt_List.keep(nextSource.destinations, (function (param) {
                  var destinationId = param.destinationId;
                  return Belt_List.some(actions$1, (function (action) {
                                if (typeof action !== "object") {
                                  return false;
                                }
                                var variant = action.NAME;
                                if (variant === "ExcludeDestinationFromSource" || variant === "IncludeDestinationInSourceV2" || variant === "LegacyToggleDestination" || variant === "LegacyIncludeDestinationInSource" || variant === "UpdateSourceDestinationMode") {
                                  return action.VAL[1] === destinationId;
                                } else {
                                  return false;
                                }
                              }));
                })), (function (nextDestinationRef) {
              var nextDestinationId = nextDestinationRef.destinationId;
              return [
                      Belt_List.getBy(source.destinations, (function (param) {
                              return param.destinationId === nextDestinationId;
                            })),
                      nextDestinationRef
                    ];
            })));
  var removedDestinations = Belt_List.toArray(Belt_List.keep(source.destinations, (function (param) {
              var destinationId = param.destinationId;
              return Belt_List.every(nextSource.destinations, (function (nextDestinationRef) {
                            return destinationId !== nextDestinationRef.destinationId;
                          }));
            })));
  var destinationModeToString = function (destinationMode) {
    switch (destinationMode) {
      case "DestinationInterface" :
          return "Destination Interface";
      case "AvoManaged" :
      case "Legacy" :
          return "Avo Managed";
      
    }
  };
  var flattenPropertyBundlesInCodegen = Belt_List.getBy(actions$1, (function (action) {
          if (typeof action === "object" && action.NAME === "AddSourceConfig") {
            return action.VAL[1] === "ForceFlattenPropertyBundlesInCodegen";
          } else {
            return false;
          }
        }));
  var canCopyChangesFromBranch = CopyChangesToBranchModal__Hooks.useCanCopyChangesFromBranch(branchId, branchStatus, hasArchive, withCopyToBranch);
  var pushPullBetweenBranchesAvailability = AvoLimits.PushPullBetweenBranches.computeAvailability(workspace);
  var match = Hooks.useDisclosure(collapsible);
  var collapseAction = match[1];
  var collapsed = match[0];
  return React.createElement(DiffComponents.Item.make, {
              borderColor: changeType === "Create" ? Styles.Color.darkGreen : (
                  changeType === "Update" ? Styles.Color.light06 : Styles.Color.darkRed
                ),
              children: React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.maxWidth(DiffComponents.maxWidthPx),
                          tl: /* [] */0
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.margin(Css.px(-15)),
                              tl: {
                                hd: Css.padding2(Css.px(20), Css.px(15)),
                                tl: {
                                  hd: Css.paddingLeft(Css.px(20)),
                                  tl: {
                                    hd: Css.borderBottom(Css.px(1), Css.solid, collapsed ? "transparent" : Styles.Color.light04),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.justifyContent("spaceBetween"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.gap(Css.px(0)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, collapsible ? React.createElement(DiffCollapseButton.make, {
                                  toggle: collapseAction.toggle,
                                  isOpen: !collapsed,
                                  changeType: changeType
                                }) : null, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                children: JsDiff.renderStringDiff(undefined, undefined, undefined, Belt_Option.getWithDefault(source.name, ""), Belt_Option.getWithDefault(nextSource.name, ""))
                              })), canCopyChangesFromBranch ? React.createElement(SimpleTooltip.make, {
                              position: "bottom",
                              tooltip: pushPullBetweenBranchesAvailability === "Available" ? "Push source to another branch" : (
                                  pushPullBetweenBranchesAvailability === "AvailableDuringTrial" ? "Push source to another branch is available during your trial" : "Upgrade your plan to push source to another branch"
                                ),
                              children: React.createElement(Button.make, {
                                    icon: "export",
                                    onClick: (function ($$event) {
                                        $$event.stopPropagation();
                                        Curry._1(globalSend, {
                                              TAG: "OpenModal",
                                              _0: {
                                                NAME: "CopyChangesToBranch",
                                                VAL: [
                                                  [[
                                                      {
                                                        originBranch: "currentBranch",
                                                        destinationBranch: undefined
                                                      },
                                                      [{
                                                          NAME: "source",
                                                          VAL: source.id
                                                        }]
                                                    ]],
                                                  undefined,
                                                  "Diff",
                                                  "ItemDiff"
                                                ]
                                              }
                                            });
                                      }),
                                    size: "tiny",
                                    style: "outline",
                                    paywallLightningIcon: pushPullBetweenBranchesAvailability !== "Available"
                                  })
                            }) : null), React.createElement(Collapsible.make, {
                        collapsed: collapsed,
                        children: null
                      }, React.createElement(Spacer.make, {
                            height: 15
                          }), Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                                  if (typeof x !== "object") {
                                    return false;
                                  }
                                  var variant = x.NAME;
                                  if (variant === "UpdateSourcePlatform" || variant === "UpdateSourcePlatformV3") {
                                    return true;
                                  } else {
                                    return variant === "UpdateSourcePlatformV2";
                                  }
                                }))) ? renderRow("Development Platform", JsDiff.renderStringDiff(undefined, undefined, undefined, Belt_Option.mapWithDefault(source.platform, "", AvoConfig.devPlatformLabel), Belt_Option.mapWithDefault(nextSource.platform, "", AvoConfig.devPlatformLabel))) : null, Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                                  if (typeof x === "object") {
                                    return x.NAME === "UpdateProgrammingLanguage";
                                  } else {
                                    return false;
                                  }
                                }))) ? renderRow("Programming Language", JsDiff.renderStringDiff(undefined, undefined, undefined, Belt_Option.mapWithDefault(source.language, "", AvoConfig.languageLabel), Belt_Option.mapWithDefault(nextSource.language, "", AvoConfig.languageLabel))) : null, Belt_Option.isSome(flattenPropertyBundlesInCodegen) && AvoConfig.doesLanguageSupportPropertyBundleObjects(nextSource.language) ? React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.justifyContent("spaceBetween"),
                                      tl: {
                                        hd: Css.padding2(Css.px(15), Css.px(20)),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.color(Styles.Color.darkGreen),
                                            tl: /* [] */0
                                          }
                                        }
                                      })
                                }, React.createElement(Icon.make, {
                                      type_: "arrowDownRightTurn",
                                      size: "small",
                                      color: Styles.Color.darkGreen
                                    }), React.createElement(Spacer.make, {
                                      width: 4
                                    }), "Flatten property bundles in Codegen"), React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.color(Styles.Color.darkOrange),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.regular),
                                          tl: {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement(Icon.make, {
                                      type_: "warningFilled",
                                      size: "small",
                                      color: Styles.Color.orange
                                    }), React.createElement(Spacer.make, {
                                      width: 4
                                    }), "Change can not be reverted after merge")) : null, Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                                  if (typeof x === "object") {
                                    return x.NAME === "UpdateLibraryName";
                                  } else {
                                    return false;
                                  }
                                }))) ? renderRow("Library Name", JsDiff.renderStringDiff(undefined, undefined, undefined, source.filename, nextSource.filename)) : null, Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                                  if (typeof x === "object") {
                                    return x.NAME === "UpdateLibraryDestination";
                                  } else {
                                    return false;
                                  }
                                }))) ? renderRow("Library Destination", JsDiff.renderStringDiff(undefined, undefined, undefined, source.path, nextSource.path)) : null, Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                                  if (typeof x !== "object") {
                                    return false;
                                  }
                                  var variant = x.NAME;
                                  if (variant === "ExcludeDestinationFromSource" || variant === "IncludeDestinationInSourceV2" || variant === "LegacyToggleDestination" || variant === "LegacyIncludeDestinationInSource") {
                                    return true;
                                  } else {
                                    return variant === "UpdateSourceDestinationMode";
                                  }
                                }))) ? React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding2(Css.px(5), Css.px(20)),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.color(Styles.Color.light11),
                                        tl: {
                                          hd: Css.marginTop(Css.px(15)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  })
                            }, "Destinations") : null, Belt_Array.map(addedOrModifiedDestinations, (function (param) {
                              var nextDestination = param[1];
                              var maybePreviousDestination = param[0];
                              return Belt_Option.mapWithDefault(Belt_List.getBy(branchModel.destinations, (function (destination) {
                                                return destination.id === nextDestination.destinationId;
                                              })), null, (function (destination) {
                                            return React.createElement(React.Fragment, {
                                                        children: renderRow(ModelUtils.getDestinationName(destination), JsDiff.renderStringDiff("inline", true, false, Belt_Option.mapWithDefault(maybePreviousDestination, "", (function (param) {
                                                                        return destinationModeToString(param.destinationMode);
                                                                      })), destinationModeToString(nextDestination.destinationMode))),
                                                        key: destination.id + "-addedOrModified"
                                                      });
                                          }));
                            })), Belt_Array.map(removedDestinations, (function (removedDestination) {
                              return Belt_Option.mapWithDefault(Belt_List.getBy(masterModel.destinations, (function (destination) {
                                                return destination.id === removedDestination.destinationId;
                                              })), null, (function (destination) {
                                            return React.createElement(React.Fragment, {
                                                        children: renderRow(ModelUtils.getDestinationName(destination), React.createElement("span", {
                                                                  className: Curry._1(Css.style, {
                                                                        hd: Css.color(Styles.Color.darkRed),
                                                                        tl: {
                                                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                          tl: /* [] */0
                                                                        }
                                                                      })
                                                                }, "Off")),
                                                        key: destination.id + "-toggle"
                                                      });
                                          }));
                            })))),
              onClick: (function (param) {
                  if (collapsible) {
                    return Curry._1(collapseAction.toggle, undefined);
                  } else {
                    return Router.Schema.pushDrawerItem(undefined, undefined, {
                                NAME: "source",
                                VAL: [
                                  source.id,
                                  "overview"
                                ]
                              });
                  }
                })
            });
}

var Source = {
  make: DiffSource$Source
};

function DiffSource(Props) {
  var actions = Props.actions;
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var branchStatus = Props.branchStatus;
  var sourceId = Props.sourceId;
  var branchId = Props.branchId;
  var withCopyToBranchOpt = Props.withCopyToBranch;
  var collapsibleOpt = Props.collapsible;
  var rightElementOpt = Props.rightElement;
  var withCopyToBranch = withCopyToBranchOpt !== undefined ? withCopyToBranchOpt : false;
  var collapsible = collapsibleOpt !== undefined ? collapsibleOpt : false;
  var rightElement = rightElementOpt !== undefined ? Caml_option.valFromOption(rightElementOpt) : null;
  var globalSend = GlobalSendContext.use();
  var source = Belt_Option.getWithDefault(Belt_List.getBy(masterModel.sources, (function (source) {
              return source.id === sourceId;
            })), TrackingPlanModel.emptySource(undefined, sourceId, "", ""));
  var nextSource = Belt_Option.getWithDefault(Belt_List.getBy(branchModel.sources, (function (source) {
              return source.id === sourceId;
            })), TrackingPlanModel.emptySource(undefined, sourceId, "", ""));
  var actions$1 = Belt_List.map(actions, (function (prim) {
          return prim[1];
        }));
  if (!actions$1) {
    return null;
  }
  var hasCreate = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "CreateSource";
          } else {
            return false;
          }
        }));
  var hasArchive = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          if (x.NAME !== "Archive") {
            return false;
          }
          var match = x.VAL;
          if (typeof match === "object") {
            return match.NAME === "Source";
          } else {
            return false;
          }
        }));
  var changeType = hasCreate !== undefined ? "Create" : (
      hasArchive !== undefined ? "Archive" : "Update"
    );
  var addedOrModifiedDestinations = Belt_List.toArray(Belt_List.map(Belt_List.keep(nextSource.destinations, (function (param) {
                  var destinationId = param.destinationId;
                  return Belt_List.some(actions$1, (function (action) {
                                if (typeof action !== "object") {
                                  return false;
                                }
                                var variant = action.NAME;
                                if (variant === "ExcludeDestinationFromSource" || variant === "IncludeDestinationInSourceV2" || variant === "LegacyToggleDestination" || variant === "LegacyIncludeDestinationInSource" || variant === "UpdateSourceDestinationMode") {
                                  return action.VAL[1] === destinationId;
                                } else {
                                  return false;
                                }
                              }));
                })), (function (nextDestinationRef) {
              var nextDestinationId = nextDestinationRef.destinationId;
              return [
                      Belt_List.getBy(source.destinations, (function (param) {
                              return param.destinationId === nextDestinationId;
                            })),
                      nextDestinationRef
                    ];
            })));
  var removedDestinations = Belt_List.toArray(Belt_List.keep(source.destinations, (function (param) {
              var destinationId = param.destinationId;
              return Belt_List.every(nextSource.destinations, (function (nextDestinationRef) {
                            return destinationId !== nextDestinationRef.destinationId;
                          }));
            })));
  var destinationModeToString = function (destinationMode) {
    switch (destinationMode) {
      case "DestinationInterface" :
          return "Destination Interface";
      case "AvoManaged" :
      case "Legacy" :
          return "Avo Managed";
      
    }
  };
  var flattenPropertyBundlesInCodegen = Belt_List.getBy(actions$1, (function (action) {
          if (typeof action === "object" && action.NAME === "AddSourceConfig") {
            return action.VAL[1] === "ForceFlattenPropertyBundlesInCodegen";
          } else {
            return false;
          }
        }));
  var canCopyChangesFromBranch = CopyChangesToBranchModal__Hooks.useCanCopyChangesFromBranch(branchId, branchStatus, hasArchive, withCopyToBranch);
  var match = Collapsible.use(collapsible);
  var collapseAction = match[1];
  var collapsed = match[0];
  return React.createElement(DiffComponents.Item.make, {
              borderColor: changeType === "Create" ? Styles.Color.darkGreen : (
                  changeType === "Update" ? Styles.Color.light06 : Styles.Color.darkRed
                ),
              children: React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.maxWidth(DiffComponents.maxWidthPx),
                          tl: /* [] */0
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.margin(Css.px(-15)),
                              tl: {
                                hd: Css.padding2(Css.px(20), Css.px(15)),
                                tl: {
                                  hd: Css.paddingLeft(Css.px(20)),
                                  tl: {
                                    hd: Css.borderBottom(Css.px(1), Css.solid, collapsed ? "transparent" : Styles.Color.light04),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.justifyContent("spaceBetween"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.gap(Css.px(0)),
                                      tl: {
                                        hd: Css.width(Css.pct(100)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                })
                          }, collapsible ? React.createElement(DiffCollapseButton.make, {
                                  toggle: collapseAction.toggle,
                                  isOpen: !collapsed,
                                  changeType: changeType
                                }) : null, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                children: JsDiff.renderStringDiff(undefined, undefined, undefined, Belt_Option.getWithDefault(source.name, ""), Belt_Option.getWithDefault(nextSource.name, ""))
                              }), React.createElement(Spacer.make, {
                                width: 4,
                                grow: 1.0
                              }), rightElement), canCopyChangesFromBranch ? React.createElement(SimpleTooltip.make, {
                              position: "bottom",
                              tooltip: "Push source to another branch",
                              children: React.createElement(Button.make, {
                                    icon: "export",
                                    onClick: (function ($$event) {
                                        $$event.stopPropagation();
                                        Curry._1(globalSend, {
                                              TAG: "OpenModal",
                                              _0: {
                                                NAME: "CopyChangesToBranch",
                                                VAL: [
                                                  [[
                                                      {
                                                        originBranch: "currentBranch",
                                                        destinationBranch: undefined
                                                      },
                                                      [{
                                                          NAME: "source",
                                                          VAL: source.id
                                                        }]
                                                    ]],
                                                  undefined,
                                                  "Diff",
                                                  "ItemDiff"
                                                ]
                                              }
                                            });
                                      }),
                                    size: "tiny",
                                    style: "outline"
                                  })
                            }) : null), React.createElement(Collapsible.Conditional.make, {
                        enabled: collapsible,
                        collapsed: collapsed,
                        children: null
                      }, React.createElement(Spacer.make, {
                            height: 15
                          }), Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                                  if (typeof x !== "object") {
                                    return false;
                                  }
                                  var variant = x.NAME;
                                  if (variant === "UpdateSourcePlatform" || variant === "UpdateSourcePlatformV3") {
                                    return true;
                                  } else {
                                    return variant === "UpdateSourcePlatformV2";
                                  }
                                }))) ? renderRow("Development Platform", JsDiff.renderStringDiff(undefined, undefined, undefined, Belt_Option.mapWithDefault(source.platform, "", AvoConfig.devPlatformLabel), Belt_Option.mapWithDefault(nextSource.platform, "", AvoConfig.devPlatformLabel))) : null, Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                                  if (typeof x === "object") {
                                    return x.NAME === "UpdateProgrammingLanguage";
                                  } else {
                                    return false;
                                  }
                                }))) ? renderRow("Programming Language", JsDiff.renderStringDiff(undefined, undefined, undefined, Belt_Option.mapWithDefault(source.language, "", AvoConfig.languageLabel), Belt_Option.mapWithDefault(nextSource.language, "", AvoConfig.languageLabel))) : null, Belt_Option.isSome(flattenPropertyBundlesInCodegen) && AvoConfig.doesLanguageSupportPropertyBundleObjects(nextSource.language) ? React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.justifyContent("spaceBetween"),
                                      tl: {
                                        hd: Css.padding2(Css.px(15), Css.px(20)),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.color(Styles.Color.darkGreen),
                                            tl: /* [] */0
                                          }
                                        }
                                      })
                                }, React.createElement(Icon.make, {
                                      type_: "arrowDownRightTurn",
                                      size: "small",
                                      color: Styles.Color.darkGreen
                                    }), React.createElement(Spacer.make, {
                                      width: 4
                                    }), "Flatten property bundles in Codegen"), React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.color(Styles.Color.darkOrange),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.regular),
                                          tl: {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement(Icon.make, {
                                      type_: "warningFilled",
                                      size: "small",
                                      color: Styles.Color.orange
                                    }), React.createElement(Spacer.make, {
                                      width: 4
                                    }), "Change can not be reverted after merge")) : null, Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                                  if (typeof x === "object") {
                                    return x.NAME === "UpdateLibraryName";
                                  } else {
                                    return false;
                                  }
                                }))) ? renderRow("Library Name", JsDiff.renderStringDiff(undefined, undefined, undefined, source.filename, nextSource.filename)) : null, Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                                  if (typeof x === "object") {
                                    return x.NAME === "UpdateLibraryDestination";
                                  } else {
                                    return false;
                                  }
                                }))) ? renderRow("Library Destination", JsDiff.renderStringDiff(undefined, undefined, undefined, source.path, nextSource.path)) : null, Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                                  if (typeof x !== "object") {
                                    return false;
                                  }
                                  var variant = x.NAME;
                                  if (variant === "ExcludeDestinationFromSource" || variant === "IncludeDestinationInSourceV2" || variant === "LegacyToggleDestination" || variant === "LegacyIncludeDestinationInSource") {
                                    return true;
                                  } else {
                                    return variant === "UpdateSourceDestinationMode";
                                  }
                                }))) ? React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding2(Css.px(5), Css.px(20)),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.color(Styles.Color.light11),
                                        tl: {
                                          hd: Css.marginTop(Css.px(15)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  })
                            }, "Destinations") : null, Belt_Array.map(addedOrModifiedDestinations, (function (param) {
                              var nextDestination = param[1];
                              var maybePreviousDestination = param[0];
                              return Belt_Option.mapWithDefault(Belt_List.getBy(branchModel.destinations, (function (destination) {
                                                return destination.id === nextDestination.destinationId;
                                              })), null, (function (destination) {
                                            return React.createElement(React.Fragment, {
                                                        children: renderRow(ModelUtils.getDestinationName(destination), JsDiff.renderStringDiff("inline", true, false, Belt_Option.mapWithDefault(maybePreviousDestination, "", (function (param) {
                                                                        return destinationModeToString(param.destinationMode);
                                                                      })), destinationModeToString(nextDestination.destinationMode))),
                                                        key: destination.id + "-addedOrModified"
                                                      });
                                          }));
                            })), Belt_Array.map(removedDestinations, (function (removedDestination) {
                              return Belt_Option.mapWithDefault(Belt_List.getBy(masterModel.destinations, (function (destination) {
                                                return destination.id === removedDestination.destinationId;
                                              })), null, (function (destination) {
                                            return React.createElement(React.Fragment, {
                                                        children: renderRow(ModelUtils.getDestinationName(destination), React.createElement("span", {
                                                                  className: Curry._1(Css.style, {
                                                                        hd: Css.color(Styles.Color.darkRed),
                                                                        tl: {
                                                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                          tl: /* [] */0
                                                                        }
                                                                      })
                                                                }, "Off")),
                                                        key: destination.id + "-toggle"
                                                      });
                                          }));
                            })))),
              onClick: (function (param) {
                  if (collapsible) {
                    return Curry._1(collapseAction.toggle, undefined);
                  } else {
                    return Router.Schema.pushDrawerItem(undefined, undefined, {
                                NAME: "source",
                                VAL: [
                                  source.id,
                                  "overview"
                                ]
                              });
                  }
                })
            });
}

var make = DiffSource;

export {
  renderRow ,
  Source ,
  make ,
}
/* Css Not a pure module */
