// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UsersStore from "./UsersStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";

var _map = {"CanCreateAdditionalWorkspaces":"CanCreateAdditionalWorkspaces"};

function userFlagToJs(param) {
  return param;
}

function userFlagFromJs(param) {
  return _map[param];
}

function flagEnabledForUser(user, flag) {
  var maybeFlags = user.userFlags;
  if (maybeFlags == null) {
    return false;
  } else {
    return Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field(flag, Json_decode.bool, param);
                    }), maybeFlags), false);
  }
}

function useFlagEnabledForUserId(userId, flag) {
  var userState = UsersStore.useUser(userId);
  if (typeof userState === "object") {
    return flagEnabledForUser(userState.VAL, flag);
  } else {
    return false;
  }
}

export {
  userFlagToJs ,
  userFlagFromJs ,
  flagEnabledForUser ,
  useFlagEnabledForUserId ,
}
/* UsersStore Not a pure module */
